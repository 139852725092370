import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link} from "react-router-dom";
import TagManager from "react-gtm-module";

function Branding() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }
    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/branding",
            page: 'Logo Design and Branding'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'cookie-policy'}>
                <Helmet>
                    <title>Logo Design and Branding</title>
                    <meta name="description" content="Digital Designs Studio - The practice that actively shapes your brand in the eyes and mind of your clients. This Marketing and Business concept is considered to be among a company's most important and valuable asset." />
                </Helmet>
                <div className="breadcrum-area breadcrumb-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-xl-4" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="300">

                                    <h1 className="title h2">Branding</h1>
                                    <p>The practice that actively shapes your brand in the eyes and mind of your clients. This Marketing and Business concept is considered to be among a company's most important and valuable asset. </p>

                            </div>
                            <div className="col-lg-6 col-xl-8">
                                    <img className="paralax-image w-100" src="/media/banner/banner-thumb-8.png"alt="Illustration" data-sal="slide-up" data-sal-duration="1000" data-sal-delay="400"/>
                            </div>
                        </div>




                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1 sal-animate" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2 sal-animate" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200">
                            <img src="/media/others/bubble-21.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3 sal-animate" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>

                <section className="section section-padding">
                    <div className="container">
                        <div className="section-heading mb-0">
                            <span className="subtitle">Branding Services*</span>
                            <h2 className="title">Find the Right Package.</h2>
                            {/*<p>These packages are best suited for <br/> .</p>*/}
                        </div>

                        <div className="row">
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="100">
                                <div className="pricing-table pricing-borderd">

                                    <div className="pricing-header">
                                        <h3 className="title">Logo Basic</h3>
                                        <span className="subtitle">If you need only the logo</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration"></span>
                                                <span className="amount">€99</span>
                                            </div>
                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">2 logo Proposals</li>
                                            <li className="yes">2 revisions</li>
                                            <li className="yes">Formats PNG, JPG, SVG, PDF</li>
                                            <li>Brand Guidelines</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="200">
                                <div className="pricing-table pricing-borderd">

                                    <div className="pricing-header">
                                        <h3 className="title">Logo Standard</h3>
                                        <span className="subtitle">Package with Logo & Branding Guide</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration"></span>
                                                <span className="amount">€159</span>

                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">4 logo Proposals</li>
                                            <li className="yes">2 revisions</li>
                                            <li className="yes">Formats PNG, JPG, SVG, PDF</li>
                                            <li className="yes">Brand Guidelines</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="300">
                                <div className="pricing-table pricing-borderd">
                                    <div className="star-icon">
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="pricing-header">
                                        <h3 className="title">Branding</h3>
                                        <span className="subtitle">Full package</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration"></span>
                                                <span className="amount">€399</span>
                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">Everything from Logo Standard</li>
                                            <li className="yes">Letterhead & Envelope</li>
                                            <li className="yes">Business Card</li>
                                            <li className="yes">Social Media Graphics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src="/media/others/bubble-4.png" alt="shape"/></li>
                    </ul>
                </section>



                <section className="section section-padding bg-color-light pb--70">
                    <div className="container">
                        <div className="section-heading mb--90">
                            <span className="subtitle">Brand Awareness</span>
                            <h2 className="title">How to creat a powerfull brand strategy</h2>

                        </div>
                        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"
                             data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/branding/process-1.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step One</span>
                                <h3 className="title">What makes you different?</h3>
                                <p>You have to identify a trait that sets you apart from your competition.</p>
                            </div>
                        </div>
                        <div className="process-work content-reverse" data-sal="slide-left"
                             data-sal-duration="1000" data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/branding/process-2.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step Two</span>
                                <h3 className="title">Be Consistent</h3>
                                <p>Try to be consistent on all the channels that your brand is using so you don't confuse your customers.</p>
                            </div>
                        </div>
                        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"
                             data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/branding/process-3.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step Three</span>
                                <h3 className="title">Greet your Clients with Positivity</h3>
                                <p>All brand strategies have to be Full of Positivity. Your clients want to feel good.</p>
                            </div>
                        </div>
                        <div className="process-work content-reverse" data-sal="slide-left"
                             data-sal-duration="1000" data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/branding/process-4.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step Four</span>
                                <h3 className="title">About Us Page</h3>
                                <p>This applyes to your website but the same is with the Bio section from instagram or Facebook. Let customers know why you are different.</p>
                            </div>
                        </div>
                        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"
                             data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/branding/process-5.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step Five</span>
                                <h3 className="title">Strengthen everything with your logo, name and url</h3>
                                <p>Make every small detail count and use the opportunities that come with your logo and business name to make it easy to remember.</p>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-17 list-unstyled">
                        <li className="shape shape-1"><img src="/media/others/bubble-24.png" alt="Bubble"/></li>
                        <li className="shape shape-2"><img src="/media/others/bubble-23.png" alt="Bubble"/></li>
                        <li className="shape shape-3"><img src="/media/others/line-4.png" alt="Line"/></li>
                        <li className="shape shape-4"><img src="/media/others/line-5.png" alt="Line"/></li>
                        <li className="shape shape-5"><img src="/media/others/line-4.png" alt="Line"/></li>
                        <li className="shape shape-6"><img src="/media/others/line-5.png" alt="Line"/></li>
                    </ul>
                </section>




                <section className="section section-padding">
                    <div className="container">
                        <div className="section-heading">
                            <span className="subtitle">Branding Projects</span>
                            <h2 className="title">Featured Clients</h2>
                        </div>
                        <div className="row row-35 isotope-list">
                            <div className="col-md-6 project ">
                                <div className="project-grid">
                                    <div className="thumbnail"><a href={'# '} onClick={doNothing}><img src="/media/project/project-3.png" alt="Nubyan Motovlog"/></a></div>
                                    <div className="content"><h4 className="title"><a href={'# '} onClick={doNothing}>Nubyan Motovlog</a></h4><span className="subtitle">Branding, Social Media, Logo</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="project-grid">
                                    <div className="thumbnail">
                                        <a href={'# '} onClick={doNothing}>
                                            <img src="/media/project/project-6.png" alt="Dus Cu Vaca"/>
                                        </a>
                                    </div>
                                    <div className="content">
                                        <h4 className="title"><a href={'# '} onClick={doNothing}>Dus Cu Vaca</a></h4>
                                        <span className="subtitle">Logo, Branding</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-16 list-unstyled">
                        <li className="shape shape-1"><img src="/media/others/circle-2.png" alt="circle"/></li>
                        <li className="shape shape-3"><img src="/media/others/bubble-1.png" alt="Line"/></li>
                    </ul>
                </section>


            </main>
        </HelmetProvider>
    );
}

export default Branding;
