import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link, useParams} from "react-router-dom";
import EstimateProject from "../sections/estimateProject";
import axios from "axios";


function Article() {



    let {postId} = useParams();
    let {slug} = useParams();
    const [post, setPost] = useState({});
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    useEffect(() => {

      axios.get(`http://localhost:3002/api/post/getFromId/${slug}`).then((data) => {
          setPost({ title: data.data[0].title, slug: data.data[0].slug, username: data.data[0].username, date: data.data[0].date_posted})
          console.log(data);
      })
    }, []);

    useEffect(() => {
        console.log(postId);
        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'online-shop'}>
                <Helmet>
                    <title>{post.title}</title>
                    <meta name="description" content="Digital Designs Studio -  Online Shops, E-commerce services" />
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><Link to="/">Home</Link></li>
                                <li className="active">Blog</li>
                            </ul>
                            <h1 className="title h2">Blog</h1>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100"><img src="/media/others/bubble-9.png" alt="Bubble"/></li>
                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200"><img src="/media/others/bubble-10.png" alt="Bubble"/></li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300"><img src="/media/others/line-4.png" alt="Line"/></li>
                    </ul>
                </div>

                    <h1>{post.title}</h1>

                <h4>{post.username}</h4>
                {new Date(post.date).toLocaleDateString('en-EN', options)}
                <EstimateProject/>
            </main>
        </HelmetProvider>
    );
}

export default Article;
