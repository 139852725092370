import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link, useParams} from "react-router-dom";
import EstimateProject from "../../sections/estimateProject";
import axios from "axios";
import TagManager from "react-gtm-module";


function FrameworksArticle() {

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/blog/articles/javascript-frameworks",
            page: 'Frameworks Article'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    let {postId} = useParams();
    let {slug} = useParams();
    const [post, setPost] = useState({});
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    useEffect(() => {

        axios.get(`http://localhost:3002/api/post/getFromId/${slug}`).then((data) => {
            setPost({ title: data.data[0].title, slug: data.data[0].slug, username: data.data[0].username, date: data.data[0].date_posted})
            console.log(data);
        })
    }, []);

    useEffect(() => {
        console.log(postId);
        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'online-shop'}>
                <Helmet>
                    <title>Most Used Javascript Frameworks of 2021</title>
                    <meta name="description" content="Digital Designs Studio -  Article Most Used Javascript Frameworks of 2021" />
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><Link to="/">Home</Link></li>
                                <li className="active">Blog</li>
                            </ul>
                            <h1 className="title h2">Most Used Javascript Frameworks of 2021</h1>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100"><img src="/media/others/bubble-9.png" alt="Bubble"/></li>
                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200"><img src="/media/others/bubble-10.png" alt="Bubble"/></li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300"><img src="/media/others/line-4.png" alt="Line"/></li>
                    </ul>
                </div>

                <section className="section-padding-equal">
                    <div className="container">
                        <div className="row row-40">
                            <div className="col-lg-8">
                                <div className="single-blog">
                                    <div className="single-blog-content blog-grid">
                                        <div className="post-thumbnail">
                                            <img src="/media/blog/blog-4.jpg" alt="Blog"/>
                                        </div>
                                        <div className="author">
                                            <div className="author-thumb">
                                                <img src="/media/blog/author-1.png" alt="Blog Author"/>
                                            </div>
                                            <div className="info">
                                                <h6 className="author-name">Bogdan M.</h6>
                                                <ul className="blog-meta list-unstyled">
                                                    <li>Dec 28, 2021</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p>Frameworks are more adaptable for the designing of websites, and hence, most website developers prefer them. JavaScript frameworks are a type of tool that makes working with JavaScript easier and smoother. These frameworks also make it possible for the programmer to code the application as a device responsive. This responsiveness is yet another reason why the JavaScript frameworks are quite popular when it comes to the question of using a high-level machine language. Let's have a look at the best JS Frameworks in 2021.</p>
                                        <p>When it comes to Forks and stars on GitHub the TOP 3 is: </p>

                                        <p>React - 182549 stars and 37234 forks</p>
                                        <p>Vue - 193112 stars and 31435 forks</p>
                                        <p>Angular - 79668 stars and 20938 forks</p>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="featured-img">
                                                    <img src="/media/blog/blog-12.jpg" alt="Blog"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="featured-img">
                                                    <img src="/media/blog/blog-13.jpg" alt="Blog"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-author">
                                        <div className="author">
                                            <div className="author-thumb">
                                                <img src="/media/blog/author-1.png" alt="Blog Author"/>
                                            </div>
                                            <div className="info">
                                                <h5 className="title"><a href="#">Bogdan M.</a></h5>
                                                <p>The brain behind all this business</p>
                                                <ul className="social-share list-unstyled">
                                                    <li><a href="https://www.facebook.com/Bogdan.Duracell"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="https://twitter.com/nubyan_official"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="https://www.linkedin.com/in/munteanu-bogdan-37152a155/"><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="https://www.instagram.com/nubyan_motovlog/"><i className="fab fa-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="axil-sidebar">

                                    {/*<div className="widget widget-categories">*/}
                                    {/*    <h4 className="widget-title">Categories</h4>*/}
                                    {/*    <ul className="category-list list-unstyled">*/}
                                    {/*        <li><a href="blog-category.html">Agency news</a></li>*/}
                                    {/*        <li><a href="blog-category.html">Blog</a></li>*/}
                                    {/*        <li><a href="blog-category.html">Information technology</a></li>*/}
                                    {/*        <li><a href="blog-category.html">New ideas</a></li>*/}
                                    {/*        <li><a href="blog-category.html">Uncategorized</a></li>*/}
                                    {/*        <li><a href="blog-category.html">Marketing &amp; branding</a></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                    <div className="widget widge-social-share">
                                        <div className="blog-share">
                                            <h5 className="title">Follow:</h5>
                                            <ul className="social-list list-unstyled">
                                                <li><a href="https://www.facebook.com/digitald.studio" target='_blank'><i
                                                    className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="https://twitter.com/digitald_studio" target='_blank'><i
                                                    className="fab fa-twitter"></i></a></li>
                                                <li><a href="https://www.instagram.com/digitaldesign_studio/" target='_blank'><i
                                                    className="fab fa-instagram"></i></a></li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="widget widget-banner-ad">
                                        {/*<a href="#">*/}
                                        {/*    <img src="/media/banner/widget-banner.png" alt="banner"/>*/}
                                        {/*</a>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <EstimateProject/>
            </main>
        </HelmetProvider>
    );
}

export default FrameworksArticle;
