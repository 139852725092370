import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss';
import './assets/css/vendor/font-awesome.css'
import './assets/css/vendor/green-audio-player.min.css'
import './assets/css/vendor/magnific-popup.css'
import './assets/css/vendor/slick.css'
import './assets/css/vendor/slick-theme.css'
// Other
import 'sal.js/dist/sal.css';

import Header from "./sections/header";
import Main from "./main";
import Footer from "./sections/footer";
import SideMenu from "./sections/sidemenu";
import Switcher from "./sections/switcher";
import sal from 'sal.js/dist/sal.js';


import {Helmet, HelmetProvider} from "react-helmet-async";
import Cookies from "js-cookie/dist/js.cookie";

function App() {
    const [scroll, setScroll] = useState(false);


    useEffect(() => {
        document.body.classList.add('sticky-header');
        sal({
            threshold: 0.1,
            once: true
        });
    }, []);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300)
        })
    }, [])

    useEffect(() => {
        if (Cookies.get('_darkmode') === 'Enabled') {
            document.body.classList.add('active-dark-mode');
        } else if (Cookies.get('_lightmode') === 'Enabled'){
            document.body.classList.add('active-light-mode');
        }
    }, [])




    return (
        <HelmetProvider>
        <div className="App">
            <Helmet>
                <title>Titlu home a asd</title>
                <meta name="description" content="" />

            </Helmet>
            <Switcher/>
            <div id="main-wrapper" className="main-wrapper">
                <a href="#main-wrapper" id="backto-top" className={scroll ? "back-to-top show" : "back-to-top"}>
                    <i className="far fa-angle-double-up"></i>
                </a>
                <Header/>
                <Main />
                <Footer/>
                <SideMenu/>
            </div>
        </div>
        </HelmetProvider>

    );
}

export default App;
