import React, {useEffect} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TagManager from "react-gtm-module";


function PrivacyPolicy({ match }) {

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/privacy-policy",
            page: 'Privacy Policy'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'privacy-policy'}>
                <Helmet>
                    <title>Privacy Policy</title>
                    <meta name="description" content="Digital Designs Studio -  Privacy Policy" />
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><a href="/">Home</a></li>

                                <React.Fragment>

                                    <li className="active">Privacy Policy</li>

                                </React.Fragment>
                            </ul>
                            <React.Fragment>

                                <h1 className="title h2">
                                    Privacy Policy
                                </h1>

                            </React.Fragment>

                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2 " data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200">
                            <img src="/media/others/bubble-11.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>


                <section className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="terms-page-content">
                                    <p>Last Update: 10.01.2022</p>
                                    <p>Your privacy is important to us. To better protect your privacy, we provide this Privacy Policy to explain our online information practices and the choices you can make about the way your information is collected and processed. <br/>
                                        This Privacy Policy applies to all information collected from or submitted to Digital Designs Studio SRL.
                                        Digital Designs Studio SRL, address  Str. Ileana Cosanzeana 11, Bl. P10, Sc.2, Ap. 55, Sector 5, Bucharest, Romania, is the Controller for the processing of your personal data for the purposes described below.</p>
                                    <h4>1. Which personal data are collected about you?</h4>
                                    <p>Digital Designs Studio SRL offers a number of services, including our search features, that do not require you to register for an account or provide any personal information to us. In order to provide our full range of services, we may collect the following types of information:
                                    </p>
                                    <h4>1.1 Information you provide us</h4>
                                    <p>If you subscribe to the Newsletter or other Digital Designs Studio SRL services or promotions that require registration, Digital Designs Studio SRL will collect personal data about you. The main categories of data collected are: your name, the name of your firm/company, email address, phone number, state, country, city and the content of the message you send with your request. <br/>
                                        When you send Digital Designs Studio SRL an email or other communications, the content of your message and our answer will be collected.
                                    </p>
                                    <h4>1.2 Log Information</h4>
                                    <p>When you use Digital Designs Studio SRL services, our servers automatically record information that your browser sends whenever you visit our website. The main categories of data collected are: web request, date and time of the request, IP address, browser type, browser language.</p>
                                        <p> Digital Designs Studio SRL also uses cookies and other tracking technologies. You can find out more about this in our Cookie Policy.<br/>
                                        Digital Designs Studio SRL implements links on its website in a format that allows it to keep track on whether these links have been followed.</p>
                                    <h4>1.3 Other information collected about you</h4>
                                    <p>Nominations on the website: your personal data will be communicated to Digital Designs Studio SRL by third parties (clients, marketing teams, other references). The main categories of data collected about you are: your name, gender, firm suffix, specialty, name of your firm, address, country, city, state, zip, fax number and email address. The person who nominates you can also choose to communicate your firm’s phone number, your phone number and a link to the firm’s website. <br/>
                                        Digital Designs Studio SRL' affiliated websites: Digital Designs Studio SRL offers services in connection with other web sites. Personal information that you provide to these websites can be sent to Digital Designs Studio SRL for the purposes described below. If you want more information on the personal data you might communicate on these websites, please consult their Privacy Policies.</p>
                                    <h4>2. What are the purposes for processing and what are the legal bases?</h4>
                                    <p>Digital Designs Studio SRL processes personal information only for the purposes for which it was collected. We periodically review our data collection, storage, and processing practices to ensure that we only collect, store, and process the personal information needed to provide or improve our services.</p>
                                    <p>Your personal data are processed for the following purposes and in accordance with the legal bases set out below:</p>

                                    <div className="policy-table">
                                        <div className="row">
                                            <div className="col-4"><strong>Category of Personal Information</strong></div>
                                            <div className="col-4"><strong>How We Use This Information</strong></div>
                                            <div className="col-4"><strong>Legal Bases for Processing</strong></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">Contact information and basic personal details (e.g.your name, email address, mailing address)</div>
                                            <div className="col-4">To communicate with you, such as through statements, updates, newsletters, or alerts</div>
                                            <div className="col-4">Our legitimate interest in marketing our products and services and communicating with you effectively.</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4"></div>
                                            <div className="col-4">To process your orders and to deliver products purchased by you</div>
                                            <div className="col-4">Our legitimate interest in performing a contract with you and in taking steps necessary to the formation of a contract for the purchase of products or services.</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4"></div>
                                            <div className="col-4">To respond to an inquiry or process a request from you</div>
                                            <div className="col-4">Our legitimate interest in communicating with you effectively for the purpose of resolving your issue.
                                                Our legitimate interest in improving our website, products, and services.</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">Information about how you access and use our website, such as the pages you visit, the time you spend on a page, whether you click on any links, what browser you use, or what web pages you visit before and/or after your visit to our website.</div>
                                            <div className="col-4">To manage and improve our website</div>
                                            <div className="col-4">Our legitimate interest to provide a website</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4"></div>
                                            <div className="col-4">To determine products and services that may be of interest to you for marketing purposes, and to develop new products and services</div>
                                            <div className="col-4">Our legitimate interest in marketing our products and services</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4"></div>
                                            <div className="col-4">Performing statistics on the use of the website (please see our cookie policy) to improve the quality of our website</div>
                                            <div className="col-4">Your consent</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">Log files and information about your device</div>
                                            <div className="col-4">Improving the performance of our website and ensuring the technical functioning of our network and services</div>
                                            <div className="col-4">Our legitimate interests in providing you with a better experience in using our website and in improving the quality of our services</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4"></div>
                                            <div className="col-4">Audit, research, and analysis in order to maintain, protect, and improve our services</div>
                                            <div className="col-4">Digital Designs Studio SRL’ legitimate interest to provide you with a better experience and improve the quality of its services</div>
                                        </div>
                                    </div>
                                    <br/><br/>

                                    <h4>Options for Personal Information</h4>
                                    <p>When you sign up for a particular service that requires registration, we ask you to provide personal information. If we use this information in a manner different from the purpose for which it was collected, then we will ask for your consent prior to such use.</p>
                                    <p>If we propose to use personal information for any purposes other than those described in this Policy and/or in the specific service notices, we will offer you an effective way to opt out of the use of personal information for those other purposes. We will not collect or use sensitive information for purposes other than those described in this Policy and/or in the specific service notices, unless we have obtained your prior consent.</p>
                                    <p>You can decline to submit personal information to any of our services, in which case Digital Designs Studio SRL may not be able to provide those services to you. </p>


                                    <h4>3. Who are the recipients of your data?</h4>
                                    <p>Your data will be used by authorized employees of Digital Designs Studio SRL when necessary for carrying out the above listed purposes.</p>
                                    <p>Digital Designs Studio SRL also communicates your data to its authorized Processors in the following categories:</p>
                                    <p>Media Partners: Digital Designs Studio SRL may provide your information for the purpose of publicizing the Digital Designs Studio SRL rankings to different regional and global media partners.
                                        Publishing Partners: Digital Designs Studio SRL may provide your information for the purpose of publishing our Digital Designs Studio SRL rankings in a Digital Designs Studio SRL magazine or book.
                                        Digital Designs Studio SRL may disclose information it collects about you if required by applicable law or to comply with a court order or an order from a public authority.</p>

                                    <h4>4. Security</h4>
                                    <p>Digital Designs Studio SRL will implement appropriate technical and organizational measures to ensure an appropriate level of security regarding the risk incurred and protect your personal data against unauthorized access, disclosure, alteration or destruction.</p>
                                    <h4>5. What are your rights? How can you exercise them?</h4>
                                    <p>Digital Designs Studio SRL makes good faith efforts to provide you with access to your personal information and either to correct this data if it is inaccurate or to delete such data at your request if it is not otherwise required to be retained by law or for legitimate business purposes.</p>
                                    <p>We ask individual users to identify themselves and the information requested to be accessed, corrected, or removed before processing such requests, and we may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, jeopardize the privacy of others, or would be extremely impractical (for instance, requests concerning information residing on backup tapes), or for which access is not otherwise required.</p>
                                    <p>In any case where we provide information access and correction, we perform this service free of charge, except if doing so would require a disproportionate effort. Some of our services have different procedures to access, correct, or delete users' personal information. We provide the details for these procedures in the specific Privacy Policy.</p>
                                    <h4>6. How long will your personal data be retained?</h4>
                                    <p>We will retain your personal information in a form that identifies you only for as long as doing so serves the purposes for which the information was initially collected as stated in this privacy policy, or as subsequently authorized. We may continue processing your personal information for longer periods, but only for the time and to the extent such processing reasonably serves the purposes of archiving in the public interest, journalism, literature and art, scientific or historical research and statistical analysis, and subject to the protection of this Privacy Policy. </p>
                                    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                                    <h4>7. Enforcement</h4>
                                    <p>Digital Designs Studio SRL periodically reviews its compliance with this Policy. Please feel free to direct any questions or concerns regarding this Policy or Digital Designs Studio SRL' treatment of personal information by contacting us through this web site or by writing to us at Privacy Matters, Digital Designs Studio SRL, address  Str. Ileana Cosanzeana 11, Bl. P10, Sc.2, Ap. 55, Sector 5, Bucharest, Romania.</p>
                                    <p>When we receive formal written complaints at this address, it is Digital Designs Studio SRL' policy to contact the complaining user regarding his or her concerns.</p>
                                    <p>We will cooperate with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that cannot be resolved between Digital Designs Studio SRL and an individual.</p>
                                    <h4>8. Do Not Sell My Information</h4>
                                    <p>You have the right to opt out of the sale of your personal information. This means your personal information will not be shared with any partners for publication purposes (including coverage in local magazines and newspapers). We do not sell any personal information to interest-based advertising organizations. Contact us at <a href="mailto:contact@digitald.studio">contact@digitald.studio</a> for more information.</p>
                                    <h4>9. "Do Not Track" Signals</h4>
                                    <p>Do Not Track is a privacy preference that users can set in their web browsers. When a user turns on the Do Not Track signal, the browser sends a message to websites requesting them not to track the user. For information about Do Not Track, visit www.allaboutdnt.org. At this time, we do not respond to Do Not Track browser settings or signals.</p>

                                    <h4>10. Changes to this Policy</h4>
                                    <p>Please note that this Privacy Policy may change from time to time. We will post any Policy changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of Policy changes). Each version of this Privacy Policy will be identified at the top of the page by its effective date and we will also keep prior versions of this Policy in an archive for your review.</p>
                                    <p>If you have any additional questions or concerns about this Policy, please feel free to contact us any time through this website or at: <a href="mailto:contact@digitald.studio">contact@digitald.studio</a>.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </HelmetProvider>
    );
}

export default PrivacyPolicy;
