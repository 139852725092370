import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TagManager from "react-gtm-module";


function TermsAndConditions({ match }) {

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/terms-and-conditions",
            page: 'Terms and Conditions'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'about-us'}>
                <Helmet>
                        <title>Terms and Conditions</title>
                        <meta name="description" content="Digital Designs Studio -  Terms and Conditions" />
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><a href="/">Home</a></li>

                                <React.Fragment>

                                            <li className="active">Terms and conditions</li>

                                </React.Fragment>
                            </ul>
                            <React.Fragment>

                                        <h1 className="title h2">
                                           Terms and conditions
                                        </h1>

                            </React.Fragment>

                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2 " data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200">
                            <img src="/media/others/bubble-11.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>


                <section className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="terms-page-content">
                                    <p>Last Update: 18.01.2022</p>


                                    <h4>General</h4>

                                    <p>These General Conditions of Use refer to the use of the websites provided by
                                        Digital Designs Studio SRL, based in Romania, Bucharest,Sector 5, Str. Ileana
                                        Cosanzeana 11, Bl. P10, Sc.2, Ap. 55, 51786, CIF RO44885710 , IBAN account:
                                        RO96BREL0002003300310100 opened at Libra Internet Bank.</p>
                                    <p>For the purposes of these Terms and Conditions "We", "Our", "Us" and “DigitalD Studio”
                                        refers to Digital Designs Studio SRL, while references to “You” and “Your”
                                        refers to the persons accessing this website (including persons who access this
                                        site on the behalf of other persons); references to "Material" or "Materials"
                                        includes data, information and databases.</p>
                                    <p>Please review these Terms and Conditions carefully before using this website.
                                        Your use of this website indicates Your agreement to be bound by these Terms and
                                        Conditions in consideration of the access provided to the website as detailed
                                        below.</p>
                                    <p> If You do not agree to these Terms and Conditions, any of the related
                                        documentation linked below or other restrictions notified to You during the
                                        course of Your use of this website You are not permitted to, and agree not to,
                                        use or access this website.</p>
                                    <p>If You are a consumer nothing in these Terms and Conditions shall affect his
                                        legal rights as a consumer.</p>

                                    <h4>Scope of these Terms and Conditions</h4>
                                    <p>These Terms and Conditions refer only to Your use of this website
                                        (https://digitald.studio) and, unless separate or additional terms apply, any
                                        application using content from this website and any mini-site on this
                                        website.</p>
                                    <p>Separate conditions apply to agreements of transportation via aircraft or any
                                        promotion which We may run from time to time.</p>
                                    <h4>Data protection and cookies</h4>

                                    <p>Any personal information You supply to Us when You use this website will be used
                                        in accordance with Our Privacy Policy. Information about Cookies and the use of
                                        Cookies on this website is provided on our Cookies page.</p>
                                    <h4>Proprietary rights</h4>
                                    <p>All Materials on this website, the appearance, organisation and layout of this
                                        website, the underlying software code and the underlying data are subject to
                                        trade marks, copyright, database rights and other registered and unregistered
                                        intellectual property rights which are owned either directly by Us or by Our
                                        licensors.</p>
                                    <p>Unless otherwise authorised within these Terms and Conditions, You must not copy,
                                        modify, alter, publish, broadcast, distribute, sell or transfer (whether in
                                        whole or in part) any Material on this website or the underlying software code
                                        or underlying data.</p>

                                    <h4>Permitted users of this website</h4>

                                    <p>Your use of this website is personal to You. We provide this website solely to
                                        permit You to view the availability of Our goods and services and to transact
                                        business with Us and for no other purpose.</p>

                                    <p>You are responsible for maintaining the confidentiality of Your website access
                                        information (such as user names, passwords and personal identification
                                        numbers).</p>
                                    <p> You may choose to permit family members, friends or colleagues to access the
                                        website on Your behalf by providing website access information to such persons.
                                        provided that:</p>
                                    <p> You must not permit access to or use of this website or any of the Material on
                                        or obtained from this website to any commercial entity or other organisation
                                        providing a service to the public.</p>
                                    <h4>Security</h4>
                                    <p>If You have reason to believe that Your access to the website is no longer secure
                                        (e.g. loss, theft or unauthorized disclosure or use of user names, passwords and
                                        personal identification numbers), You must promptly change the affected access
                                        information.</p>
                                    <h4>Appropriate use</h4>
                                    <p>Your access to this website is on a temporary basis, and we reserve the right to
                                        withdraw, restrict or amend the services accessibly to You on the website
                                        without notice at our absolute discretion.</p>
                                    <p>You may only use this website in accordance with these Terms and Conditions and,
                                        in any event, for lawful and proper purposes which includes complying with all
                                        applicable laws, regulations and codes of practice within Romania or other
                                        jurisdiction from which You are accessing this website. Except as expressly
                                        permitted by Us, You shall not use this website for any purpose.</p>
                                    <p>In particular, You agree that, You must not:</p>
                                    <ul>
                                        <li>post, transmit or disseminate any information on or via this website which
                                            is or may be harmful, obscene, defamatory or otherwise illegal;
                                        </li>
                                        <li>use ‘screen scraping’, any automated algorithm, device, method, system,
                                            software or manual process to access, use, search, copy, monitor or extract
                                            Material (in whole or in part) from or through using this website unless We
                                            have given Our express written agreement;
                                        </li>
                                        <li>use this website in a manner which causes or may cause an infringement of
                                            the rights of any other;
                                        </li>
                                        <li>use any software, routine or device to interfere or attempt to interfere
                                            electronically or manually with the operation or functionality of this
                                            website including but not limited to uploading or making available files
                                            containing corrupt data or viruses via whatever means;
                                        </li>
                                        <li>deface, alter or interfere with the front end ‘look and feel’ of this
                                            website or the underlying software code;
                                        </li>
                                        <li>take any action that imposes an unreasonable or disproportionately large
                                            load on this website or related infrastructure
                                        </li>
                                        <li>permit any automatic registration, logging in or access to any of the
                                            Material on the website;
                                        </li>
                                        <li>obtain or attempt to obtain unauthorised access, via whatever means, to any
                                            of Our networks or accounts or to information about other users
                                        </li>
                                        <li>disclose confidential information of any person or entity including without
                                            limitation Our confidential information.
                                        </li>
                                    </ul>


                                    <h4>Changes to this website</h4>

                                    <p>We may make improvements or changes to the information, services, products and
                                        other Materials on this website, or terminate this website, at any time without
                                        notice. We may also modify these Terms and Conditions at any time, and such
                                        modification shall be effective immediately upon posting of the modified Terms
                                        and Conditions on this website. Accordingly, Your continued access or use of
                                        this website is deemed to be Your acceptance of the modified Terms and
                                        Conditions.</p>
                                    <h4>Links to other websites</h4>
                                    <p>This website may include links to other internet sites. We are not responsible
                                        for the information, Material, products or services contained on or accessible
                                        through those websites. Your access and use of such websites remains solely at
                                        Your own risk.</p>

                                    <p>You may only link to this website with Our express written permission. We
                                        expressly reserve the right to withdraw Our consent at any time to a link which
                                        in Our sole opinion is inappropriate or controversial.</p>


                                    <h4>Limitation of liability</h4>

                                    <p>In no event will We be liable for any direct, indirect, special, punitive,
                                        exemplary or consequential losses or damages of whatsoever kind arising out of
                                        access to, or the use of this website or any information contained in it,
                                        including loss of profit and the like whether or not in the contemplation of the
                                        parties, whether based on breach of contract, tort (including negligence),
                                        product liability or otherwise, even if advised of the possibility of such
                                        damages.</p>
                                    <p>Nothing in these Terms and Conditions shall exclude or limit Our liability for
                                        death or personal injury caused by negligence or for fraud and fraudulent
                                        misrepresentation.</p>
                                    <p>All software products downloaded from any section of this website or via a link
                                        pointed to by this website are downloaded, installed, and used totally and
                                        entirely at the users own risk.</p>

                                    <h4>Disclaimer of warranty</h4>

                                    <p>To the maximum extent permitted by law, We disclaim all implied warranties with
                                        regard to the information, services and Materials contained on this website. All
                                        such information, services and Materials are provided "as is" and "as available"
                                        without warranty of any kind.</p>
                                    <p>Digital Designs Studio SRL makes no warranty whatsoever for the reliability,
                                        stability or any virus-free nature of any software being downloaded from this
                                        website, nor for the availability of the download sites where applicable.</p>
                                    <h4>Applicable law</h4>

                                    <p>The Agreement shall be governed by and construed in accordance with the laws of
                                        Romania.</p>
                                    <h4>Court of jurisdiction</h4>
                                    <p>Any and all disputes arising from or in connection with the Agreement including
                                        its formation and validity, shall unless otherwise stated by mandatory law be
                                        subject to the exclusive jurisdiction of the competent court for Romania. For
                                        legal actions against Digital Designs Studio SRL by consumers the mandatory
                                        legal provisions of the romanian Consumer Protection Act apply.</p>

                                    <h4>Miscellaneous</h4>

                                    <p>These Terms and Conditions contain all the terms of Your agreement with Us
                                        relating to Your use of this website. No other written or oral statement
                                        (including statements in any brochure or promotional literature published by Us)
                                        will be incorporated.</p>

                                    <p>Any failure to assert any rights We may have under these Terms and Conditions
                                        does not constitute a waiver of Our right to assert the same or any other right
                                        at any other time or against any other person.</p>

                                    <p> If any provision of this Agreement is found to be invalid or unenforceable, then
                                        the invalid or unenforceable provision will be removed from these Terms and
                                        Conditions without affecting the validity or enforceability of any other
                                        provision.</p>

                                    <p>Throughout this website the terms "partner(s)" and "partnership(s)" are used to
                                        refer to individual marketing or co-operation agreements and not to any
                                        relationship which has specific legal or tax implications. Therefore, Digital
                                        Designs Studio SRL cannot accept any liability for the conduct of these partner
                                        organisations.</p>

                                    <p>Throughout this website the term "person(s)" is used to refer to natural and
                                        legal persons.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </HelmetProvider>
    );
}

export default TermsAndConditions;
