import React from 'react';


const FormInput = ({ handleChange, label, error, ...otherProps }) => (
    <div className='form-group'>
        {label ? (
            <label
                className={`${
                    otherProps.value.length ? 'labelMove' : ''
                } form-input-label`}
            >
                {label}
            </label>
        ) : null}
        <input className='form-control' onChange={handleChange} {...otherProps} />
        <span className="text-danger mt-2">{error}</span>

    </div>
);

export default FormInput;