import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import TagManager from "react-gtm-module";

function NotFound() {

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/404-page",
            page: '404 Page'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    return (

    <HelmetProvider>
        <section className="error-page onepage-screen-area">
            <Helmet>
                <title>Sorry 404 error!</title>
                <meta name="description" content="This page doesn't exists. Go back to homepage."/>
            </Helmet>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="content sal-animate" data-sal="slide-up" data-sal-duration="800"
                             data-sal-delay="400">
                            <h2 className="title">Page not found</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore </p>
                            <Link to="/" className="axil-btn btn-fill-primary">Go Back</Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="thumbnail sal-animate" data-sal="zoom-in" data-sal-duration="800"
                             data-sal-delay="400">
                            <img src="/media/others/404.png" alt="404"/>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1 sal-animate" data-sal="slide-right" data-sal-duration="500"
                    data-sal-delay="100">
                    <img src="/media/others/bubble-9.png" alt="Bubble"/>
                </li>
                <li className="shape shape-2 sal-animate" data-sal="slide-left" data-sal-duration="500"
                    data-sal-delay="200">
                    <img src="/media/others/bubble-27.png" alt="Bubble"/>
                </li>
                <li className="shape shape-3 sal-animate" data-sal="slide-up" data-sal-duration="500" data-sal-delay="300">
                    <img src="/media/others/line-4.png" alt="Line"/>
                </li>
            </ul>
        </section>
    </HelmetProvider>
    );
}

export default NotFound;
