import React, {useEffect, useState} from "react";
import sal from "sal.js";
import {Helmet, HelmetProvider} from "react-helmet-async";
import axios from "axios";
import {useParams} from "react-router-dom";


function EditArticle() {
    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);
    let {postId} = useParams();
    const [title, setTitle] = useState("");
    const [username, setUsername] = useState("");
    const [content, setContent] = useState("");
    const [tags, setTags] = useState("");
    const [date, setDate] = useState("");

    const [post, setPost] = useState({});
    const options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
    useEffect(() => {

        axios.get(`http://localhost:3002/api/post/getFromId/${postId}`).then((data) => {
            setPost({ title: data.data[0].title, content: data.data[0].post_text, username: data.data[0].username, date: data.data[0].date_posted, tags: data.data[0].tags,})
            console.log(data);
            console.log(data);
        })
    }, []);

    const submitPost = () => {
        alert('edit article');
        axios.put(`http://localhost:3002/api/post/edit/${postId}`, {title: title, content: content, username: username, tags: tags, date: date});
        alert(title, content, username, tags, date);

    }

    useEffect(() => {

        let title = "Contact Title";

    }, [])


    return (
        <HelmetProvider>
            <main className={'edit-article'}>
                <Helmet>
                    <title>Edit Article</title>
                    <meta name="description" content="Get in touch with Digital Designs Studio"/>
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><a href="index-1.html">Home</a></li>
                                <li className="active">Edit Article</li>
                            </ul>
                            <h1 className="title h2">Edit Article</h1>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500" data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500" data-sal-delay="200">
                            <img src="/media/others/bubble-17.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500" data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>
                <section className="section section-padding container">
                    <div className="uploadPost contact-form-box shadow-box mb--30">
                        <div className="form-group">
                            <label className=" form-input-label">Title</label>
                            <input className="form-control" name="title" type="text" defaultValue={post.title} onChange={(e) => {setTitle(e.target.value);}}/>
                        </div>
                        <div className="form-group">
                            <label className=" form-input-label">Article Content</label>
                            <textarea className='form-control textarea' name="content" id="" cols="30" rows="10" defaultValue={post.content} placeholder='' onChange={(e) => {setContent(e.target.value);}}></textarea>
                        </div>
                        <div className="form-group">
                            <label className="form-input-label">Username</label>
                            <input className="form-control" name="username" type="text" defaultValue={post.username} onChange={(e) => {setUsername(e.target.value);}}/>
                        </div>
                        <div className="form-group">
                            <label className="form-input-label">Tags</label>
                            <input className="form-control" name="tags" type="text" defaultValue={post.tags} onChange={(e) => {setTags(e.target.value);}}/>
                        </div>
                        <div className="form-group">
                            <label className="form-input-label">Date</label>
                            <input className="form-control" name="date" type="date" defaultValue={post.date} onChange={(e) => {setDate(e.target.value);}}/>
                        </div>
                        <button type={'submit'}
                                className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                onClick={submitPost}>Edit article
                        </button>

                    </div>
                </section>

            </main>
        </HelmetProvider>
    );
}

export default EditArticle;
