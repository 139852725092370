
import React, {useEffect, useState} from "react";
import Isotope from "isotope-layout/dist/isotope.pkgd.min";
import imagesLoaded from "imagesloaded/imagesloaded";


function PortofolioList() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }

    //const [projects, setProjects] = useState([]);

    const data = [
        {
            "id": "1",
            "title": "Manesti 2022",
            "type": "graphics",
            "tags": "Graphics, Campaign, Social",
            "description": "test",
            "link": "#"
        },
        {
            "id": "2",
            "title": "Signature FitRoom",
            "type": "webdesign",
            "tags": "Webdesign, Wordpress & Php",
            "description": "test",
            "link": "https://signaturefitroom.ro/"
        },
        {
            "id": "3",
            "title": "Nubyan Motovlog",
            "type": "branding",
            "tags": "Branding, Social Media, Logo",
            "description": "test",
            "link": "#"
        },
        {
            "id": "4",
            "title": "End of Season BH",
            "type": "graphics",
            "tags": "Graphics, Poster, Event",
            "description": "test",
            "link": "#"
        },
        {
            "id": "5",
            "title": "Danceroom.ro",
            "type": "webdesign",
            "tags": "Webdesign, Wordpress",
            "description": "test",
            "link": "https://www.danceroom.ro/"
        },
        {
            "id": "6",
            "title": "Dus Cu Vaca",
            "type": "branding",
            "tags": "Logo, Branding",
            "description": "test",
            "link": "#"
        },
        {
            "id": "7",
            "title": "Jurnalul Morociclistilor",
            "type": "branding",
            "tags": "Logo, Branding",
            "description": "test",
            "link": "#"
        },
        {
            "id": "8",
            "title": "BlackHelmets.ro",
            "type": "webdesign",
            "tags": "Webdesign, Wordpress",
            "description": "test",
            "link": "https://blackhelmets.ro/"
        },
        {
            "id": "9",
            "title": "motodent.ro",
            "type": "webdesign",
            "tags": "Webdesign, Wordpress",
            "description": "test",
            "link": "https://motodent.ro/"
        }
    ];




    function shuffle(array) {
        array.sort(() => Math.random() - 0.5);
    }

    shuffle(data);

    useEffect(() => {

        imagesLoaded( '.axil-isotope-wrapper', function() {
            const divs = document.querySelectorAll('.isotope-button button');
            divs.forEach(el => el.addEventListener('click', event => {
                let filterValue = el.getAttribute('data-filter');
                iso.arrange({
                    filter: filterValue
                });
                iso.arrange();
            }));
            // document.querySelectorAll('.isotope-button button').addEventListener('click',  function () {
            //
            // });
            var elem = document.querySelector('.isotope-list');
            var iso = new Isotope( elem, {
                // options
                itemSelector: '.project',
                percentPosition: true,
                transitionDuration: '0.7s',
                layoutMode: 'fitRows',
                masonry: {
                    // use outer width of grid-sizer for columnWidth
                    columnWidth: 1,
                }
            });

        });

        var el = document.querySelectorAll('.isotope-button button');
        for (let i = 0; i < el.length; i++) {
            el[i].onclick = function() {
                var c = 0;
                while (c < el.length) {
                    el[c++].className = 'slide';
                }
                el[i].className = 'slide is-checked';
            };
        }

    }, [])

    return (
        <section className="section section-padding-2">
            <div className="container">
                <div className="section-heading heading-left mb--40">
                    <span className="subtitle">Our Projects</span>
                    <h2 className="title">Some of our <br/> finest work.</h2>
                </div>


                <div className="axil-isotope-wrapper">

                    <div className="isotope-button isotope-project-btn">
                        <button data-filter="*" className="is-checked"><span
                            className="filter-text">All Works</span></button>
                        <button data-filter=".branding" className=""><span className="filter-text">Branding</span>
                        </button>
                        <button data-filter=".webdesign" className=""><span className="filter-text">Webdesign</span>
                        </button>
                        <button data-filter=".graphics" className=""><span className="filter-text">Graphics</span>
                        </button>
                    </div>
                    <div className="row row-35 isotope-list">
                        {data.map((item) => (
                            <div key={item.id} className={'col-md-6 project ' + item.type}>
                                <div className="project-grid">
                                    <div className="thumbnail">
                                        <a href={item.link}>
                                            <img src={'/media/project/project-' + item.id+ '.jpg'} alt={item.title}/>
                                        </a>
                                    </div>
                                    <div className="content">
                                        <h4 className="title"><a href={item.link}>{item.title}</a></h4>
                                        <span className="subtitle">{item.tags}</span>
                                    </div>
                                </div>
                            </div>


                        ))}
                    </div>
                </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
                <li className="shape shape-1"><img src="/media/others/circle-2.png" alt="circle"/></li>
                <li className="shape shape-2"><img src="/media/others/bubble-2.png" alt="Line"/></li>
                <li className="shape shape-3"><img src="/media/others/bubble-1.png" alt="Line"/></li>
            </ul>
        </section>
    );
}

export default PortofolioList;
