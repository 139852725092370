import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TagManager from "react-gtm-module";


function CookiePolicy({ match }) {


    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/cookie-policy",
            page: 'Cookie Policy'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)


    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'cookie-policy'}>
                <Helmet>
                    <title>Cookie Policy</title>
                    <meta name="description" content="Digital Designs Studio -  Cookie Policy" />
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><a href="/">Home</a></li>

                                <React.Fragment>

                                    <li className="active">Cookie Policy</li>

                                </React.Fragment>
                            </ul>
                            <React.Fragment>

                                <h1 className="title h2">
                                    Cookie Policy
                                </h1>

                            </React.Fragment>

                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2 " data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200">
                            <img src="/media/others/bubble-11.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>


                <section className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="terms-page-content">
                                    <p>Last Update: 10.01.2022</p>
                                    <p>The Cookie Policy Page explains how Digital Designs Studio SRL ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our website at http://digitald.studio/, ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
                                    <p>We use "cookies" and similar technologies (such as web beacons and pixels) on our websites. Cookies are small data files that that are placed on your computer when you access our websites. First-party cookies are set by us. Third-party cookies are set by others, such as analytics providers or advertisers.</p>
                                    <p>Most browsers can be set to detect cookies and give you an opportunity to reject them, but refusing cookies may, in some cases, inhibit or preclude your use of the Website or its features. You can learn more about cookies at www.allaboutcookies.org.</p>
                                    <h4>Why We Use Cookies</h4>
                                    <p>We use cookies to improve your access to our websites (for example, by storing your username so you can log in more easily), and to allow you to share website content with social media platforms like Facebook, Instagram, or Twitter. Cookies also enable us to track and target the interests of visitors to our websites, so that we can provide more customized content. Cookies can also be used to collect general usage and aggregated statistical information.
                                    </p>
                                    <h4>Cookies We Use</h4>
                                    <p>There are different types of cookies and other technologies used on our Website:
                                    </p>
                                    <p>"Session cookies” – These cookies only last as long as your online session, and disappear from your computer or device when you close your browser."<br/>
                                        "Persistent cookies” – These cookies stay on your computer or device after your browser has been closed and last for a time specified in the cookie. We use persistent cookies when we need to know who you are for more than one browsing session. For example, we use them to remember your preferences for the next time you visit.<br/>
                                        "Web Beacons/Pixels” – Some of our web pages and emails may also contain small electronic images known as web beacons, clear GIFs, or single-pixel GIFs. These images typically work in conjunction with cookies to collect data, such as counting the number of users who have visited a web page or acted upon one of our email digests.<br/>
                                        Analytics/Performance Cookies. We also use the following analytic tools to help us analyze the performance of our Website and Services as well as how visitors use our Website and Services:</p>

                                    <p>Google Analytics — Google Analytics collects information such as how often users visit our site, what pages they visit when they do so, and what other sites they used prior to coming to our site. We use the information we get from Google Analytics to improve our site. Google Analytics collects only the IP address assigned to you on the date you visit our site, rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics with personally identifiable information. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit our site, the cookie cannot be used by anyone but Google. Google’s ability to use and share information collected by Google Analytics about your visits to our site is restricted by the Google Analytics Terms of Use. To opt-out of being tracked by Google Analytics across all websites visit https://tools.google.com/dlpage/gaoptout. This will allow you to download and install a Google Analytics cookie-free web browser.</p>
                                    <h4>Google Tag Manager</h4>
                                    <p>Facebook, Twitter, and other Social Network Cookies. Our content pages allow you to share content appearing on our website to your social media accounts through the “Like,” “Tweet,” or similar buttons displayed on such pages. To accomplish this, we embed code that such third party social networks provide and that we do not control. These buttons know that you are logged in to your social network account and therefore such social networks could also know that you are viewing our website.</p>
                                    <h4>How often the Cookie Policy page is updated?</h4>
                                    <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
                                    <p>The date at the top of this page indicates when it was last updated.</p>
                                    <h4>Where can I get further info?</h4>
                                    <p>If you have any questions about our use of cookies or other technologies, please email us at contact@digitald.studio or by post to:</p>
                                    <p><strong>Digital Designs Studio SRL</strong><br/>
                                        Str. Ileana Cosanzeana 11, Bl. P10, Sc.2, Ap. 55<br/>
                                        Sector 5, Bucharest<br/>
                                        Romania<br/>
                                        Phone: +40 738 247 561</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </HelmetProvider>
    );
}

export default CookiePolicy;
