import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import Header from "../sections/header";
import EstimateProject from "../sections/estimateProject";
import PortofolioList from "../sections/portofolioList";
import TagManager from "react-gtm-module";


function Homepage() {
    const [doc, setDocData] = useState(null);


    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/",
            page: 'Homepage - Digital Designs Studio'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, [])
    let title;


    useEffect(() => {

        let title = "Welcome to Digital Designs Studio";

    }, [])

    return (
        <HelmetProvider>
          <main className={'homepage'}>
              <Helmet>
                  <title>{title}</title>
                  <meta name="description" content="Your stop to digital online marketing services"/>

              </Helmet>
              <section className="banner banner-style-1">
                  <div className="container">
                      <div className="row align-items-center">
                          <div className="col-lg-6">
                              <div className="banner-content" data-sal="slide-up" data-sal-duration="1000"
                                   data-sal-delay="100">
                                  <h1 className="title">Do you need the best website for your business?</h1>
                                  <span className="subtitle">We are dedicated to providing our clients with high quality, user friendly websites at an affordable price.</span>
                                  <Link to="/contact" className="axil-btn btn-fill-primary btn-large">Get Started</Link>
                              </div>
                          </div>
                          <div className="col-lg-6">
                              <div className="banner-thumbnail">
                                  <div className="large-thumb" data-sal="zoom-in" data-sal-duration="500"
                                       data-sal-delay="300">
                                      <img src="/media/banner/laptop.png" alt="Laptop"/>
                                  </div>
                                  <ul className="list-unstyled shape-group">
                                      <li className="shape shape-1" data-sal="slide-right"
                                          data-sal-duration="500" data-sal-delay="400">
                                          <img src="/media/banner/marker.png" alt="Marker"/>
                                      </li>
                                      <li className="shape shape-2" data-sal="slide-right"
                                          data-sal-duration="500" data-sal-delay="500">
                                          <img src="/media/banner/chat-icon.png" alt="Chat"/>
                                      </li>
                                      <li className="shape shape-3" data-sal="slide-left"
                                          data-sal-duration="500" data-sal-delay="600">
                                          <img src="/media/banner/sticker.png" alt="sticker"/>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <ul className="list-unstyled shape-group-13">
                      <li className="shape shape-1" data-sal="slide-down" data-sal-duration="500"
                          data-sal-delay="100">
                          <img src="/media/others/bubble-18.png" alt="Bubble"/>
                      </li>
                      <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500"
                          data-sal-delay="200">
                          <img src="/media/others/bubble-19.png" alt="Bubble"/>
                      </li>
                      <li className="shape shape-3" data-sal="slide-left" data-sal-duration="500"
                          data-sal-delay="700">
                          <img src="/media/others/hand-2.png" alt="Hand"/>
                      </li>
                      <li className="shape shape-4" data-sal="slide-left" data-sal-duration="500"
                          data-sal-delay="700">
                          <img src="/media/others/bubble-14.png" alt="Bubble"/>
                      </li>
                      <li className="shape shape-5" data-sal="slide-left" data-sal-duration="500"
                          data-sal-delay="700">
                          <img src="/media/others/bubble-14.png" alt="Bubble"/>
                      </li>
                  </ul>
              </section>
              <section className="section section-padding-equal bg-color-dark">
                  <div className="container">
                      <div className="section-heading heading-light-left">
                          <span className="subtitle">A wide variety of services</span>
                          <h2 className="title">Don't hesitate to give us a shout if you need</h2>
                          {/*<p className="opacity-50">Nulla facilisi. Nullam in magna id dolor*/}
                          {/*    blandit rutrum eget vulputate augue sed eu imperdiet.</p>*/}
                      </div>
                      <div className="row">
                          <div className="col-lg-4 col-md-6" data-sal="slide-up" data-sal-duration="800"
                               data-sal-delay="100">
                              <div className="services-grid">
                                  <div className="thumbnail">
                                      <img src="/media/icon/icon-1.png" alt="icon"/>
                                  </div>
                                  <div className="content">
                                      <h5 className="title"><Link to="/branding">Logo & Branding</Link></h5>
                                      <p>We create a custom visual identity for your company and products, based on intensive research to bring everything on point and appealing for your clients.</p>
                                      {/*<a href="service-design.html" className="more-btn">Find out more</a>*/}
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-6" data-sal="slide-up" data-sal-duration="800"
                               data-sal-delay="200">
                              <div className="services-grid">
                                  <div className="thumbnail">
                                      <img src="/media/icon/icon-2.png" alt="icon"/>
                                  </div>
                                  <div className="content">
                                      <h5 className="title"><Link to="/web-development">Web Development</Link></h5>
                                      <p>We can cover a wide array of website types: blog, presentation, corporate, online shop or other. Contact us if you need help with either of these.</p>
                                      {/*<a href="service-development.html" className="more-btn">Find out more</a>*/}
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-6" data-sal="slide-up" data-sal-duration="800"
                               data-sal-delay="300">
                              <div className="services-grid">
                                  <div className="thumbnail">
                                      <img src="/media/icon/icon-3.png" alt="icon"/>
                                  </div>
                                  <div className="content">
                                      <h5 className="title"><Link to="/online-marketing">Online marketing</Link></h5>
                                      <p>You can use social media, search engine optimization (SEO), blogging, video marketing and more to spread the word about your business.</p>
                                      {/*<a href="service-marketing.html" className="more-btn">Find out more</a>*/}
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-6" data-sal="slide-up" data-sal-duration="800"
                               data-sal-delay="100">
                              <div className="services-grid">
                                  <div className="thumbnail">
                                      <img src="/media/icon/icon-4.png" alt="icon"/>
                                  </div>
                                  <div className="content">
                                      <h5 className="title"><Link to="/online-shop">Online Shop</Link></h5>
                                      <p>In a world where the online sales have increased over 50% during the pandemic, having an online presence is a must.</p>
                                      {/*<a href="service-business.html" className="more-btn">Find out more</a>*/}
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-6" data-sal="slide-up" data-sal-duration="800"
                               data-sal-delay="200">
                              <div className="services-grid">
                                  <div className="thumbnail">
                                      <img src="/media/icon/icon-5.png" alt="icon"/>
                                  </div>
                                  <div className="content">
                                      <h5 className="title"><Link to="/seo">SEO</Link></h5>
                                      <p>SEO helps you get more traffic from the search engines by increasing your rankings.</p>
                                      {/*<a href="service-technology.html" className="more-btn">Find out more</a>*/}
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-6" data-sal="slide-up" data-sal-duration="800"
                               data-sal-delay="300">
                              <div className="services-grid">
                                  <div className="thumbnail">
                                      <img src="/media/icon/icon-6.png" alt="icon"/>
                                  </div>
                                  <div className="content">
                                      <h5 className="title"><Link to="/site-maintenance">Site Maintenance</Link>
                                      </h5>
                                      <p>If you you need help with speed improvements for your site, updates or backups, we have specific monthly plans or you can go with a hourly rate of our services.</p>
                                      {/*<a href="service-content-strategy.html" className="more-btn">Find out more</a>*/}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <ul className="list-unstyled shape-group-10">
                      <li className="shape shape-1"><img src="/media/others/circle-1.png" alt="Circle"/></li>
                      <li className="shape shape-2"><img src="/media/others/line-3.png" alt="Circle"/></li>
                      <li className="shape shape-3"><img src="/media/others/bubble-5.png" alt="Circle"/></li>
                  </ul>
              </section>


              <section className="section section-padding">
                  <div className="container">
                      <div className="section-heading heading-left">
                          <span className="subtitle">Customer Testimonials</span>
                          <h2 className="title">Your satisfaction drives us for perfection</h2>
                          <p>This is the reason of why we do all the wonderfull stuff for out clients</p>
                      </div>
                      <div className="row">
                          <div className="col-lg-4" data-sal="slide-up" data-sal-duration="1000"
                               data-sal-delay="100">
                              <div className="testimonial-grid">
                                  <span className="social-media"><img src="/media/icon/fb-2.png"
                                                                      alt="Yelp"/></span>
                                  <p>“ Optimal solutions, tailored to the customer's needs! I recommend !!! ”</p>
                                  <div className="author-info">
                                      <div className="thumb">
                                          <img src="/media/testimonial/testimonial-1.png" alt="Google Review"/>
                                      </div>
                                      <div className="content">
                                          <span className="name">Mihai M.</span>
                                          <span className="designation">Owner @ Dus cu Vaca</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4" data-sal="slide-up" data-sal-duration="1000"
                               data-sal-delay="200">
                              <div className="testimonial-grid">
                                  <span className="social-media"><img src="/media/icon/fb-2.png" alt="google"/></span>
                                  <p>“ Seriousness (what you rarely find in this field), exceptional value for money, results as expected. I have been working with Digital Designs Studio for 7 years and I am very satisfied. I recommend with confidence !!! ”</p>
                                  <div className="author-info">
                                      <div className="thumb">
                                          <img src="/media/testimonial/testimonial-2.png" alt="Facebook Review"/>
                                      </div>
                                      <div className="content">
                                          <span className="name">Adelina N.</span>
                                          <span className="designation">Owner Danceroom.ro</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4" data-sal="slide-up" data-sal-duration="1000"
                               data-sal-delay="300">
                              <div className="testimonial-grid">
                                  <span className="social-media"><img src="/media/icon/google-2.png"
                                                                      alt="Google Review"/></span>
                                  <p>“ I recommend for the proven professionalism when helping me out with the website!. ”</p>
                                  <div className="author-info">
                                      <div className="thumb">
                                          <img src="/media/testimonial/testimonial-3.png" alt="Google Review"/>
                                      </div>
                                      <div className="content">
                                          <span className="name">Marian A.</span>
                                          <span className="designation">CEO Promarshop</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <ul className="shape-group-4 list-unstyled">
                      <li className="shape-1"><img src="/media/others/bubble-1.png" alt="Bubble"/></li>
                  </ul>
              </section>

              <PortofolioList/>


              <section className="section section-padding bg-color-dark">
                  <div className="container">

                      <div className="section-heading heading-light-left">
                          <span className="subtitle">Our Clients</span>
                          <h2 className="title">We’ve built solutions for...</h2>
                          <p>Design anything from logos to fully featured websites, applications or graphic design elements.</p>
                      </div>
                      <div className="row">
                          <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration="500">
                              <div className="brand-grid">
                                  <img src="/media/brand/brand-1.png" alt="Brand"/>
                              </div>
                          </div>
                          <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration="500"
                               data-sal-delay="100">
                              <div className="brand-grid">
                                  <img src="/media/brand/brand-2.png" alt="Brand"/>
                              </div>
                          </div>
                          <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration="500"
                               data-sal-delay="200">
                              <div className="brand-grid">
                                  <img src="/media/brand/brand-3.png" alt="Brand"/>
                              </div>
                          </div>
                          <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration="500"
                               data-sal-delay="300">
                              <div className="brand-grid">
                                  <img src="/media/brand/brand-4.png" alt="Brand"/>
                              </div>
                          </div>
                          <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration="500"
                               data-sal-delay="400">
                              <div className="brand-grid">
                                  <img src="/media/brand/brand-5.png" alt="Brand"/>
                              </div>
                          </div>
                          <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration="500"
                               data-sal-delay="500">
                              <div className="brand-grid">
                                  <img src="/media/brand/brand-6.png" alt="Brand"/>
                              </div>
                          </div>
                          <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration="500"
                               data-sal-delay="600">
                              <div className="brand-grid">
                                  <img src="/media/brand/brand-7.png" alt="Brand"/>
                              </div>
                          </div>
                          <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration="500"
                               data-sal-delay="700">
                              <div className="brand-grid">
                                  <img src="/media/brand/brand-8.png" alt="Brand"/>
                              </div>
                          </div>
                      </div>
                  </div>
                  <ul className="shape-group-2 list-unstyled">
                      <li className="shape shape-1"><img src="/media/others/circle-1.png" alt="circle"/></li>
                      <li className="shape shape-2"><img src="/media/others/line-3.png" alt="circle"/></li>
                      <li className="shape shape-3"><img src="/media/others/bubble-3.png" alt="circle"/></li>
                  </ul>
              </section>

              <section className="section section-padding-equal">
                  <div className="container">
                      <div className="section-heading">
                          <span className="subtitle">Webdesign Tips and Trends</span>
                          <h2 className="title">Latest articles</h2>
                          <p>News From Around The World, All About Web and Graphic Design.</p>
                      </div>
                      <div className="row g-0">
                          <div className="col-xl-6" data-sal="slide-right" data-sal-duration="800"
                               data-sal-delay="100">
                              <div className="blog-list">
                                  <div className="post-thumbnail">
                                      <Link to="/blog/articles/choosing-domain-and-hosting"><img src="/media/blog/blog-1.jpg" alt="Blog Post"/></Link>
                                  </div>
                                  <div className="post-content">
                                      <h5 className="title"><Link to="/blog/articles/choosing-domain-and-hosting">Choosing the Domain and Hosting for your site</Link></h5>
                                      <p>When it comes to choose the domain and hosting for your website you have to
                                          make sure to follow...</p>
                                      <Link to="/blog/articles/choosing-domain-and-hosting" className="more-btn">Learn more<i
                                          className="far fa-angle-right"></i></Link>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-6" data-sal="slide-left" data-sal-duration="800"
                               data-sal-delay="100">
                              <div className="blog-list border-start">
                                  <div className="post-thumbnail">
                                      <Link to="/blog/articles/javascript-frameworks"><img src="/media/blog/blog-2.jpg" alt="Blog Post"/></Link>
                                  </div>
                                  <div className="post-content">
                                      <h5 className="title"><Link to="/blog/articles/javascript-frameworks">Most Used Javascript Frameworks of 2021</Link></h5>
                                      <p>Here you can find the trend from last year when it comes to Javascript Frameworks. Most used framework is ...</p>
                                      <Link to="/blog/articles/javascript-frameworks" className="more-btn">Learn more<i
                                          className="far fa-angle-right"></i></Link>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <ul className="shape-group-1 list-unstyled">
                      <li className="shape shape-1"><img src="/media/others/bubble-1.png" alt="bubble"/></li>
                      <li className="shape shape-2"><img src="/media/others/line-1.png" alt="bubble"/></li>
                      <li className="shape shape-3"><img src="/media/others/line-2.png" alt="bubble"/></li>
                      <li className="shape shape-4"><img src="/media/others/bubble-2.png" alt="bubble"/></li>
                  </ul>
              </section>

              <EstimateProject/>


          </main>
        </HelmetProvider>
    );
}

export default Homepage;
