import React, {useEffect} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link} from "react-router-dom";
import TagManager from "react-gtm-module";

function WebDevelopment() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/web-development",
            page: 'Web Development'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'cookie-policy'}>
                <Helmet>
                    <title>Web Development</title>
                    <meta name="description" content="Digital Designs Studio -  Wide range of web services that cover everything from blogs, presentation, corporate or e-commerce websites." />
                </Helmet>
                <div className="breadcrum-area breadcrumb-banner">
                    <div className="container">
                        <div className="section-heading heading-left" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="300">
                            <h1 className="title h2">Web Development</h1>
                            <p>Wide range of web services that cover everything from blogs, presentation, corporate or e-commerce websites.</p>
                        </div>
                        <div className="banner-thumbnail " data-sal="slide-up" data-sal-duration="1000" data-sal-delay="400">
                            <img className="paralax-image" src="/media/banner/banner-thumb-4.png"alt="Illustration"/>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1 sal-animate" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2 sal-animate" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200">
                            <img src="/media/others/bubble-21.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3 sal-animate" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>

                <section className="section section-padding">
                    <div className="container">
                        <div className="section-heading mb-0">
                            <span className="subtitle">Pricing Packages*</span>
                            <h2 className="title">Find the Right Plan.</h2>
                            <p>These packages are best suited for <br/> personal, blogs, corporate sites.</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="100">
                                <div className="pricing-table pricing-borderd">

                                    <div className="pricing-header">
                                        <h3 className="title">Basic</h3>
                                        <span className="subtitle">Simple Static Site</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">from</span>
                                                <span className="amount">€199</span>

                                            </div>
                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">max 5 Pages</li>
                                            <li className="yes">Responsive Website</li>
                                            <li className="yes">Contact Form</li>
                                            <li>Administration panel (CMS)</li>
                                            <li>Gallery</li>
                                            <li>Blog</li>
                                            <li>Social Media Links</li>
                                            <li>Google Analytics Set-up</li>
                                            <li>Gdpr Cookie Popup</li>
                                            <li>SEO OnPage</li>
                                            <li>Maintenance</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="200">
                                <div className="pricing-table pricing-borderd">
                                    <div className="star-icon">
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="pricing-header">
                                        <h3 className="title">Premium</h3>
                                        <span className="subtitle">Medium Package for Blogs or Business Sites</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">from</span>
                                                <span className="amount">€399</span>

                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">max 10 Pages</li>
                                            <li className="yes">Responsive Website</li>
                                            <li className="yes">Contact Form</li>
                                            <li className="yes">Administration panel (CMS)</li>
                                            <li className="yes">Gallery</li>
                                            <li className="yes">Blog</li>
                                            <li>Social Media Links</li>
                                            <li>Google Analytics Set-up</li>
                                            <li>Gdpr Cookie Popup</li>
                                            <li>SEO OnPage</li>
                                            <li>Maintenance</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="300">
                                <div className="pricing-table pricing-borderd">
                                    <div className="pricing-header">
                                        <h3 className="title">Corporate</h3>
                                        <span className="subtitle">Large Bussiness CMS Site</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">from</span>
                                                <span className="amount">€599</span>
                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">max 20 Pages</li>
                                            <li className="yes">Responsive Website</li>
                                            <li className="yes">Contact Form</li>
                                            <li className="yes">Administration panel (CMS)</li>
                                            <li className="yes">Gallery</li>
                                            <li className="yes">Blog</li>
                                            <li className="yes">Social Media Links</li>
                                            <li className="yes">Google Analytics Set-up</li>
                                            <li className="yes">Gdpr Cookie Popup</li>
                                            <li className="yes">SEO OnPage</li>
                                            <li className="yes">Maintenance - 1 month</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <p>*The domain and hosting for the websites are not included. If they are not already purchased by the client, we would gladly recommend third-party providers for them.<br/>
                            If you are looking for the e-commerce website packages, you can find them <a href="">here</a>.</p>
                    </div>
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src="/media/others/bubble-4.png" alt="shape"/></li>
                    </ul>
                </section>



                <section className="section section-padding bg-color-light pb--70">
                    <div className="container">
                        <div className="section-heading mb--90">
                            <span className="subtitle">How we do it</span>
                            <h2 className="title">Our website creation steps</h2>
                            <p>Our development process ensures a perfectly crafted site for your
                                business.</p>
                        </div>
                        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"
                             data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/process-1.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step One</span>
                                <h3 className="title">Gather Information</h3>
                                <p>Get the main information from the client about the bussiness details, business goals, the target audience and competition.</p>
                            </div>
                        </div>
                        <div className="process-work content-reverse" data-sal="slide-left"
                             data-sal-duration="1000" data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/process-2.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step Two</span>
                                <h3 className="title">Make a Plan</h3>
                                <p>Define a layout for the website. Get the list of pages, their placement and relationship between each other.</p>
                            </div>
                        </div>
                        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"
                             data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/process-3.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step Three</span>
                                <h3 className="title">Implement & Develop</h3>
                                <p>Here we start with the coding and design process of the website.</p>
                            </div>
                        </div>
                        <div className="process-work content-reverse" data-sal="slide-left"
                             data-sal-duration="1000" data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/process-4.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step four</span>
                                <h3 className="title">Testing</h3>
                                <p>Tests for making sure that the website looks perfect on all the devices and browsers. Our priority is to have a full responsive website that the visitors will enjoy.</p>
                            </div>
                        </div>
                        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"
                             data-sal-delay="100">
                            <div className="thumbnail paralax-image">
                                <img src="/media/others/process-5.png" alt="Thumbnail"/>
                            </div>
                            <div className="content">
                                <span className="subtitle">Step Five</span>
                                <h3 className="title">Launch the Website</h3>
                                <p>After all development and testing is ready we get a final review from the client for last changes before we get the site live. After all of the feedback is done we proceed and launch the site.</p>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-17 list-unstyled">
                        <li className="shape shape-1"><img src="/media/others/bubble-24.png" alt="Bubble"/></li>
                        <li className="shape shape-2"><img src="/media/others/bubble-23.png" alt="Bubble"/></li>
                        <li className="shape shape-3"><img src="/media/others/line-4.png" alt="Line"/></li>
                        <li className="shape shape-4"><img src="/media/others/line-5.png" alt="Line"/></li>
                        <li className="shape shape-5"><img src="/media/others/line-4.png" alt="Line"/></li>
                        <li className="shape shape-6"><img src="/media/others/line-5.png" alt="Line"/></li>
                    </ul>
                </section>
                
                
                
                
                <section className="section section-padding">
                    <div className="container">
                        <div className="section-heading">
                            <span className="subtitle">Web Projects</span>
                            <h2 className="title">Featured Designs</h2>
                        </div>
                        <div className="row row-35 isotope-list">
                            <div className="col-md-6 project ">
                                <div className="project-grid">
                                    <div className="thumbnail"><a href={'# '} onClick={doNothing}><img src="/media/project/project-5.png" alt="Danceroom.ro"/></a></div>
                                    <div className="content"><h4 className="title"><a href={'# '} onClick={doNothing}>Danceroom.ro</a></h4><span className="subtitle">Webdesign, Wordpress, Responsive</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="project-grid">
                                    <div className="thumbnail">
                                        <a href={'# '} onClick={doNothing}>
                                            <img src="/media/project/project-2.png" alt="project"/>
                                        </a>
                                    </div>
                                    <div className="content">
                                        <h4 className="title"><a href={'# '} onClick={doNothing}>Signature FitRoom</a></h4>
                                        <span className="subtitle">Webdesign, Wordpress & Php</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-16 list-unstyled">
                        <li className="shape shape-1"><img src="/media/others/circle-2.png" alt="circle"/></li>
                        <li className="shape shape-3"><img src="/media/others/bubble-1.png" alt="Line"/></li>
                    </ul>
                </section>


            </main>
        </HelmetProvider>
    );
}

export default WebDevelopment;
