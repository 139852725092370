import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link} from "react-router-dom";
import TagManager from "react-gtm-module";

function OnlineShop() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/online-shop",
            page: 'Online Shops'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'online-shop'}>
                <Helmet>
                    <title>Online Shops</title>
                    <meta name="description" content="Digital Designs Studio -  Online Shops, E-commerce services" />
                </Helmet>
                <div className="breadcrum-area breadcrumb-banner">

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content" data-sal="slide-up" data-sal-duration="800" data-sal-delay="400">
                                    <h2 className="title">Online Shops</h2>
                                    <p>Helping your bussiness with a platform for selling to your clients over the internet. </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="thumbnail" data-sal="zoom-in" data-sal-duration="800"
                                     data-sal-delay="400"><img src="/media/others/online-shops.png" alt="ecommerce"/></div>
                            </div>
                        </div>
                    </div>

                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1 sal-animate" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2 sal-animate" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200">
                            <img src="/media/others/bubble-21.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3 sal-animate" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>

                <section className="section section-padding">
                    <div className="container">
                        <div className="section-heading mb-0">
                            <span className="subtitle">Ecommerce Packages*</span>
                            <h2 className="title">Find the Right Shop for You.</h2>
                            {/*<p>These packages are best suited for <br/> .</p>*/}
                        </div>

                        <div className="row">
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="100">
                                <div className="pricing-table pricing-borderd">

                                    <div className="pricing-header">
                                        <h3 className="title">Basic Shop</h3>
                                        <span className="subtitle">If you need only the logo</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">from</span>
                                                <span className="amount">€799</span>
                                            </div>
                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">less than 50 products imported</li>
                                            <li className="yes">1 email address</li>
                                            <li className="yes">1 Shipping Method</li>
                                            <li className="yes">1 Payment Method</li>
                                            <li className="no">Credit Card Payments</li>
                                            <li className="no">Google Analytics</li>
                                            <li className="no">Google Maps/Places</li>
                                            <li className="no">Social Media Links</li>
                                            <li className="no">Gdpr Cookie Popup</li>
                                            <li className="no">SEO OnPage</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="200">
                                <div className="pricing-table pricing-borderd">

                                    <div className="pricing-header">
                                        <h3 className="title">Standard Shop</h3>
                                        <span className="subtitle">Package with Logo & Branding Guide</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">from</span>
                                                <span className="amount">€999</span>

                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">less than 100 products imported</li>
                                            <li className="yes">3 email address</li>
                                            <li className="yes">2 Shipping Method</li>
                                            <li className="yes">2 Payment Method</li>
                                            <li className="yes">Credit Card Payments</li>
                                            <li className="yes">Google Analytics</li>
                                            <li className="yes">Google Maps/Places</li>
                                            <li className="yes">Social Media Links</li>
                                            <li className="no">Gdpr Cookie Popup</li>
                                            <li className="no">SEO OnPage</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="300">
                                <div className="pricing-table pricing-borderd">
                                    <div className="star-icon">
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="pricing-header">
                                        <h3 className="title">Premium Shop</h3>
                                        <span className="subtitle">Full package</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">from</span>
                                                <span className="amount">€1499</span>
                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <a href="#" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</a>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">less than 250 products imported</li>
                                            <li className="yes">10 email address</li>
                                            <li className="yes">2 Shipping Method</li>
                                            <li className="yes">3 Payment Method</li>
                                            <li className="yes">Credit Card Payments</li>
                                            <li className="yes">Google Analytics</li>
                                            <li className="yes">Google Maps/Places</li>
                                            <li className="yes">Social Media Links</li>
                                            <li className="yes">Gdpr Cookie Popup</li>
                                            <li className="yes">SEO OnPage</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <p>*The domain and hosting for the websites are not included. If they are not already purchased by the client, we would gladly recommend third-party providers for them.<br/>
                           </p>
                    </div>
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src="/media/others/bubble-4.png" alt="shape"/></li>
                    </ul>
                </section>




                {/*<section className="section section-padding bg-color-light pb--70">*/}
                {/*    <div className="container">*/}
                {/*        <div className="section-heading mb--90">*/}
                {/*            <span className="subtitle">Brand Awareness</span>*/}
                {/*            <h2 className="title">How to creat a powerfull brand strategy</h2>*/}

                {/*        </div>*/}
                {/*        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"*/}
                {/*             data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-1.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step One</span>*/}
                {/*                <h3 className="title">What makes you different?</h3>*/}
                {/*                <p>You have to identify a trait that sets you apart from your competition.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="process-work content-reverse" data-sal="slide-left"*/}
                {/*             data-sal-duration="1000" data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-2.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step Two</span>*/}
                {/*                <h3 className="title">Be Consistent</h3>*/}
                {/*                <p>Try to be consistent on all the channels that your brand is using so you don't confuse your customers.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"*/}
                {/*             data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-3.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step Three</span>*/}
                {/*                <h3 className="title">Greet your Clients with Positivity</h3>*/}
                {/*                <p>All brand strategies have to be Full of Positivity. Your clients want to feel good.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="process-work content-reverse" data-sal="slide-left"*/}
                {/*             data-sal-duration="1000" data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-4.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step Four</span>*/}
                {/*                <h3 className="title">About Us Page</h3>*/}
                {/*                <p>This applyes to your website but the same is with the Bio section from instagram or Facebook. Let customers know why you are different.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"*/}
                {/*             data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-5.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step Five</span>*/}
                {/*                <h3 className="title">Strengthen everything with your logo, name and url</h3>*/}
                {/*                <p>Make every small detail count and use the opportunities that come with your logo and business name to make it easy to remember.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <ul className="shape-group-17 list-unstyled">*/}
                {/*        <li className="shape shape-1"><img src="/media/others/bubble-24.png" alt="Bubble"/></li>*/}
                {/*        <li className="shape shape-2"><img src="/media/others/bubble-23.png" alt="Bubble"/></li>*/}
                {/*        <li className="shape shape-3"><img src="/media/others/line-4.png" alt="Line"/></li>*/}
                {/*        <li className="shape shape-4"><img src="/media/others/line-5.png" alt="Line"/></li>*/}
                {/*        <li className="shape shape-5"><img src="/media/others/line-4.png" alt="Line"/></li>*/}
                {/*        <li className="shape shape-6"><img src="/media/others/line-5.png" alt="Line"/></li>*/}
                {/*    </ul>*/}
                {/*</section>*/}




                {/*<section className="section section-padding">*/}
                {/*    <div className="container">*/}
                {/*        <div className="section-heading">*/}
                {/*            <span className="subtitle">Marketing Projects</span>*/}
                {/*            <h2 className="title">Featured Clients</h2>*/}
                {/*        </div>*/}
                {/*        <div className="row row-35 isotope-list">*/}
                {/*            <div className="col-12 offset-md-3 col-md-6 project ">*/}
                {/*                <div className="project-grid">*/}
                {/*                    <div className="thumbnail"><a href={'# '} onClick={doNothing}><img src="/media/project/project-3.png" alt="Nubyan Motovlog"/></a></div>*/}
                {/*                    <div className="content"><h4 className="title"><a href={'# '} onClick={doNothing}>Nubyan Motovlog</a></h4><span className="subtitle">Youtube - 5300 Subscribers, Instagram - 4900 Followers</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <ul className="shape-group-16 list-unstyled">*/}
                {/*        <li className="shape shape-1"><img src="/media/others/circle-2.png" alt="circle"/></li>*/}
                {/*        <li className="shape shape-3"><img src="/media/others/bubble-1.png" alt="Line"/></li>*/}
                {/*    </ul>*/}
                {/*</section>*/}


            </main>
        </HelmetProvider>
    );
}

export default OnlineShop;
