import { Link } from "react-router-dom";
import cookieBanner from "./cookieComponent";

function Footer() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }


    return (
        <footer className="footer-area">

            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a href="https://www.facebook.com/digitald.studio" data-sal="slide-up" data-sal-duration="500"
                                   data-sal-delay="100"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/digitald_studio" data-sal="slide-up" data-sal-duration="500"
                                   data-sal-delay="200"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" data-sal="slide-up" data-sal-duration="500"
                                   data-sal-delay="400"><i className="fab fa-linkedin-in"></i></a></li>
                            <li><a href="https://www.instagram.com/digitaldesign_studio/" data-sal="slide-up" data-sal-duration="500"
                                   data-sal-delay="500"><i className="fab fa-instagram"></i></a></li>

                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5" data-sal="slide-right" data-sal-duration="800" data-sal-delay="100">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="footer-widget mb-4">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to="/branding">Logo &amp; Branding</Link></li>
                                                <li><Link to="/web-development">Web Development</Link></li>
                                                <li><Link to="/online-marketing">Online Marketing</Link></li>
                                                <li><Link to="/online-shop">Online Shop</Link></li>
                                                <li><Link to="/seo">SEO</Link></li>
                                                <li><Link to="/site-maintenance">Site Maintenance</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to="/contact">Contact</Link></li>
                                                <li><Link to="/terms-and-conditions">Terms of Use</Link></li>
                                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                                <li><Link to="/cookie-policy">Cookie Policy</Link></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/*<div className="addthis_inline_follow_toolbox"></div>*/}
                            {/*<div className="footer-widget border-end">*/}
                            {/*    <div className="footer-newsletter">*/}
                            {/*        <h2 className="title">Get in touch!</h2>*/}
                            {/*        <p>Fusce varius, dolor tempor interdum tristique, dui urna bib*/}
                            {/*            endum magna, ut ullamcorper purus</p>*/}
                            {/*        <form>*/}
                            {/*            <div className="input-group">*/}
                            {/*                <input type="email" className="form-control" placeholder="Email address"/>*/}
                            {/*                    <button className="subscribe-btn" type="submit">Subscribe</button>*/}
                            {/*            </div>*/}
                            {/*        </form>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-xl-6 col-lg-7" data-sal="slide-left" data-sal-duration="800"
                             data-sal-delay="100">
                            <div className="row">
                                <div className="col-sm-6">

                                    <div className="footer-widget mb-4">
                                        <h6 className="widget-title">Resourses</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to="/blog">Blog</Link></li>
                                                <li><Link to="/portofolio">Portfolio</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="footer-widget mb-4">
                                                <h6 className="widget-title">DIGITAL DESIGNS STUDIO S.R.L.</h6>
                                                <div className="footer-menu-link">
                                                    <ul className="list-unstyled">
                                                        <li>Address: Romania, Bucharest, <br/>Str. Ileana Cosanzeana, Nr. 11</li>
                                                        <li>CUI: 44885710</li>
                                                        <li>J40/15653/2021</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">

                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom" data-sal="slide-up" data-sal-duration="500" data-sal-delay="100">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {(new Date().getFullYear())}. All rights reserved by <a href="https://digitald.studio">Digital Designs Studio S.R.L.</a></span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-and-conditions">Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {cookieBanner()}
        </footer>
    );
}

export default Footer;
