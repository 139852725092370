import React, {useEffect} from "react";
import sal from "sal.js";
import Switcher from "../sections/switcher";
import Header from "../sections/header";
import Main from "../main";
import Footer from "../sections/footer";
import SideMenu from "../sections/sidemenu";
import validate from './form/validateInfo';
import useForm from './form/useForm';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Link} from "react-router-dom";
import FormInput from "./form/FormInput";
import FormTextarea from "./form/FormTextarea";
import FormCheckbox from "./form/FormCheckbox";
import TagManager from "react-gtm-module";

const Contact = ({ submitForm }) => {
    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);
    let title;
    const { handleChange, handleSubmit, values, errors } = useForm(
        submitForm,
        validate
    );
    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/contact",
            page: 'Contact'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {

        let title = "Contact Title";

    }, [])

    return (
        <HelmetProvider>
            <main className={'contact-page'}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content="Get in touch with Digital Designs Studio"/>
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><a href="index-1.html">Home</a></li>
                                <li className="active">Contact</li>
                            </ul>
                            <h1 className="title h2">Contact</h1>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500" data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500" data-sal-delay="200">
                            <img src="/media/others/bubble-17.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500" data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>
                <section className="section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6">
                                <div className="contact-form-box shadow-box mb--30">
                                    <h3 className="title">Message Us</h3>
                                    <form onSubmit={handleSubmit} method="POST" noValidate className="axil-contact-form">
                                        <FormInput
                                            name='name'
                                            type='text'
                                            onChange={handleChange}
                                            value={values.name}
                                            label={'Name'}
                                            error={errors.name}
                                            required
                                        />
                                        <FormInput
                                            name='email'
                                            type='text'
                                            onChange={handleChange}
                                            value={values.email}
                                            label={'Email'}
                                            error={errors.email}
                                            required
                                        />
                                        <FormInput
                                            name='company'
                                            type='text'
                                            onChange={handleChange}
                                            value={values.company}
                                            label={'Company'}
                                            error={errors.company}

                                        />

                                        <FormTextarea
                                            name='message'
                                            type='textarea'
                                            onChange={handleChange}
                                            value={values.message}
                                            label={'How can we help you?'}
                                            error={errors.message}
                                            cols="30"
                                            rows="4"

                                        />

                                        <FormCheckbox
                                            name='privacy'
                                            type='checkbox'
                                            onChange={handleChange}
                                            value={values.privacy}
                                            error={errors.privacy}
                                            />


                                        <div className="form-group">
                                            <button type={'submit'}
                                                    className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                                    name="submit-btn">Send Message
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 offset-xl-1">
                                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                    <h4 className="title">Phone</h4>
                                    <p>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</p>
                                    <h4 className="phone-number"><a href="tel:+40 738 247 561">+40 738 247 561</a></h4>
                                </div>
                                <div className="contact-info mb--30">
                                    <h4 className="title">Email</h4>
                                    <p>Our support team will get back to in 48-h during standard business hours.</p>
                                    <h4 className="phone-number"><a href="mailto:contact@digitald.studio">contact@digitald.studio</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-12">
                        <li className="shape shape-1"><img src="/media/others/bubble-2.png" alt="Bubble"/></li>
                        <li className="shape shape-2"><img src="/media/others/bubble-1.png" alt="Bubble"/></li>
                        <li className="shape shape-3"><img src="/media/others/circle-3.png" alt="Circle"/></li>
                    </ul>
                </section>

            </main>
        </HelmetProvider>
    );
}

export default Contact;
