

export default function validateInfo(values) {


    let errors = {}
    if (!values.name.trim()) {
        errors.name = "Name required"
    }

    if (!values.email.trim()) {
        errors.email = "Email required"
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }
    // if (!values.privacy.length == 1) {
    //     errors.privacy = "value required"
    // }
    if (!values.privacy.trim()) {
        errors.privacy = "You have to agree with the Terms of Use and Privacy Policy"
    }


    return errors;
}

