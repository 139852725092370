import React, {useEffect, useState} from "react";
import sal from "sal.js";
import {Helmet, HelmetProvider} from "react-helmet-async";
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function CreateArticle() {
    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    const [title, setTitle] = useState("");
    const [username, setUsername] = useState("");
    const [content, setContent] = useState("");
    const [slug, setSlug] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [tags, setTags] = useState("");
    const [date, setDate] = useState("");
    
    const submitPost = () => {
      axios.post('http://localhost:3002/api/post/create', {title: title, slug: slug, content: content, selectedFile: selectedFile,  username: username, tags: tags, date: date})
    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }
    useEffect(() => {

        let title = "Contact Title";

    }, [])


    return (
        <HelmetProvider>
            <main className={'create-article'}>
                <Helmet>
                    <title>Create Article</title>
                    <meta name="description" content="Get in touch with Digital Designs Studio"/>
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><a href="index-1.html">Home</a></li>
                                <li className="active">Create Article</li>
                            </ul>
                            <h1 className="title h2">Create Article</h1>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500" data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500" data-sal-delay="200">
                            <img src="/media/others/bubble-17.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500" data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>
                <section className="section section-padding container">
                    <div className="uploadPost contact-form-box shadow-box mb--30">
                        <div className="form-group">
                            <label className=" form-input-label">Title</label>
                            <input className="form-control" name="title" type="text" onChange={(e) => {setTitle(e.target.value);}}/>
                        </div>
                        <div className="form-group">
                            <label className=" form-input-label">Slug</label>
                            <input className="form-control" name="slug" type="text" onChange={(e) => {setSlug(e.target.value);}}/>
                        </div>
                        <div className="form-group">
                            <label className=" form-input-label">Article Content</label>
                            {/*<textarea className='form-control textarea' name="content" id="" cols="30" rows="10" onChange={(e) => {setContent(e.target.value);}}></textarea>*/}
                            <ReactQuill theme="snow" name="content" onChange={setContent}/>
                        </div>
                        <div className="form-group">
                            <label className="form-input-label">Username</label>
                            <input className="form-control" name="username" type="text" onChange={(e) => {setUsername(e.target.value);}}/>
                        </div>
                        <div className="form-group">
                            <label className="form-input-label">Tags</label>
                            <input className="form-control" name="tags" type="text" onChange={(e) => {setTags(e.target.value);}}/>
                        </div>
                        <div className="form-group">
                            <label className="form-input-label">Image</label>
                            <input className="form-control" name="image" type="file"   onChange={handleFileSelect}/>

                        </div>
                        <div className="form-group">
                            <label className="form-input-label">Date</label>
                            <input className="form-control" name="date" type="date" onChange={(e) => {setDate(e.target.value);}}/>
                        </div>
                        <button type={'submit'}
                                className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                onClick={submitPost}>Add article
                        </button>


                    </div>
                </section>

            </main>
        </HelmetProvider>
    );
}

export default CreateArticle;
