import React from 'react';


const FormTextarea = ({ handleChange, label, error, ...otherProps }) => (
    <div className='form-group'>
        {label ? (
            <label
                className={`${
                    otherProps.value.length ? 'labelMove' : ''
                } form-input-label`}
            >
                {label}
            </label>
        ) : null}
        <textarea className='form-control textarea' onChange={handleChange} {...otherProps}  cols="30" rows="4"></textarea>
        <span className="text-danger">{error}</span>

    </div>
);

export default FormTextarea;