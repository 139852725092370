import { Link } from "react-router-dom";

function SideMenu() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }
    function openSideRightMenu() {
        document.querySelector('.header-offcanvasmenu').classList.toggle('isShowing');
    }

    return (
        <div className="offcanvas offcanvas-end header-offcanvasmenu" tabIndex="-1" id="offcanvasMenuRight">
            <div className="offcanvas-header">
                <button type="button" className="btn-close" onClick={openSideRightMenu}></button>
            </div>
            <div className="offcanvas-body">
                {/*<form action="#" className="side-nav-search-form">*/}
                {/*    <div className="form-group">*/}
                {/*        <input type="text" className="search-field" name="search-field" placeholder="Search..."/>*/}
                {/*            <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>*/}
                {/*    </div>*/}
                {/*</form>*/}
                <div className="row ">

                    <div className="col-12">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Email: <a href="mailto:contact@digitald.studio">contact@digitald.studio</a></p>
                                </address>
                                <address className="address">
                                    <span className="title">We're available Mon-Fri, <br/>10am/6pm GMT. Call Now.</span>
                                    <a className="tel" href="tel:+40 738 247 561"><i className="fas fa-phone"></i>+40 738 247 561</a>

                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li><a href="https://www.facebook.com/digitald.studio"><i className="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li><a href="https://www.instagram.com/digitaldesign_studio/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="https://twitter.com/digitald_studio"><i className="fab fa-twitter"></i></a>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SideMenu;
