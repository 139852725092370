import { Link } from "react-router-dom";
import {useEffect, useState} from "react";
import Cookies from "js-cookie/dist/js.cookie";


function Header() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }
    function openSideRightMenu() {
        document.querySelector('.header-offcanvasmenu').classList.toggle('isShowing');
    }


    function openMobileSubMenu() {
        document.querySelector('.axil-submenu').classList.toggle('show');
        document.querySelector('.submenu-toogle').classList.toggle('rotate');
    }
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 200)
        })
    }, [])

    function openMobileMenu() {
        document.querySelector('#mobilemenu-popup').classList.toggle('show');
    }


    function changeTheme() {
        if (document.body.classList.contains('active-dark-mode')) {
            Cookies.remove('_darkmode');
            Cookies.set('_lightmode', 'Enabled', { expires: 7 });
            document.body.classList.remove('active-dark-mode');
            document.body.classList.add('active-light-mode');

        } else if (document.body.classList.contains('active-light-mode')) {
            Cookies.remove('_lightmode');
            Cookies.set('_darkmode', 'Enabled', { expires: 7 });
            document.body.classList.remove('active-light-mode');
            document.body.classList.add('active-dark-mode');
            console.log("Setted Cookie light");
        } else  {
            Cookies.set('_darkmode', 'Enabled', { expires: 7 });
            document.body.classList.add('active-dark-mode');
            console.log("Setted Cookie dark for the first time");
        }
    }

    return (
        <header className="header axil-header header-style-1">
            <div id="axil-sticky-placeholder" className={scroll ? "axil-sticky-placeholder scrolled" : "axil-sticky-placeholder"}></div>
            <div className={scroll ? "axil-mainmenu axil-sticky" : "axil-mainmenu"}>
                <div className="container">
                    <div className="header-navbar">
                        <div className="header-logo">
                            <Link to="/"><img className="light-version-logo" src="/media/logo.svg"alt="logo"/></Link>
                            <Link to="/"><img className="dark-version-logo" src="/media/logo-3.svg" alt="logo"/></Link>
                            <Link to="/"><img className="sticky-logo" src="/media/logo-2.svg" alt="logo"/></Link>
                        </div>
                        <div className="header-main-nav">
                            <nav className="mainmenu-nav" id="mobilemenu-popup">
                                <div className="d-block d-lg-none">
                                    <div className="mobile-nav-header">
                                        <div className="mobile-nav-logo">
                                            <a href="index-1.html">
                                                <img className="light-mode" src="/media/logo-2.svg"alt="Site Logo"/>
                                                <img className="dark-mode" src="/media/logo-3.svg" alt="Site Logo"/>
                                            </a>
                                        </div>
                                        <button className="mobile-menu-close" onClick={openMobileMenu}><i className="fas fa-times"></i></button>
                                    </div>
                                </div>
                                <ul className="mainmenu">
                                    <li>
                                        <Link to="/" onClick={openMobileMenu}>Homepage</Link>
                                    </li>
                                    <li><Link to="/about-us" onClick={openMobileMenu}>About Us</Link></li>
                                    <li className="menu-item-has-children">
                                        <a href={'# '} className="submenu-toogle" onClick={openMobileSubMenu}>Services</a>
                                        <ul className="axil-submenu">
                                            <li><Link to="/branding" onClick={openMobileMenu}>Logo &amp; Branding</Link></li>
                                            <li><Link to="/web-development" onClick={openMobileMenu}>Web Development</Link></li>
                                            <li><Link to="/online-marketing" onClick={openMobileMenu}>Online Marketing</Link></li>
                                            <li><Link to="/online-shop" onClick={openMobileMenu}>Online Shop</Link></li>
                                            <li><Link to="/seo" onClick={openMobileMenu}>SEO</Link></li>
                                            <li><Link to="/site-maintenance" onClick={openMobileMenu}>Site Maintenance</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/portofolio" onClick={openMobileMenu}>Portfolio</Link>
                                    </li>
                                    <li>
                                        <Link to="/blog"  onClick={openMobileMenu}>Blog</Link>
                                    </li>
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                            </nav>

                        </div>
                        <div className="header-action">
                            <ul className="list-unstyled">
                                <li className="sidemenu-btn d-lg-block d-none">
                                    <button className="btn-wrap" onClick={openSideRightMenu}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </li>
                                <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                                    <button className="btn-wrap" onClick={openMobileMenu}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </li>
                                <li className="my_switcher d-block d-lg-none" onClick={changeTheme}>
                                    <ul>
                                        <li title="Light Mode">
                                            <button className="setColor light" data-theme="light">
                                                <i className="fal fa-lightbulb-on"></i>
                                            </button>
                                        </li>
                                        <li title="Dark Mode">
                                            <button className="setColor dark" data-theme="dark">
                                                <i className="fas fa-moon"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
