import React, { useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import sal from 'sal.js/dist/sal.js';
import Cookies from "js-cookie/dist/js.cookie";
import Home from '../src/pages/homepage';
import AboutUs from '../src/pages/about-us';
import Contact from '../src/pages/contact';
import TermsAndConditions from "./pages/terms-and-conditions";
import NotFound from "./pages/404";
import ScrollToTop from "./sections/scrollToTop";
import PrivacyPolicy from "./pages/privacy-policy";
import CookiePolicy from "./pages/cookie-policy";
import Portofolio from "./pages/portofolio";
import WebDevelopment from "./pages/services/web-development";
import Branding from "./pages/services/branding";
import OnlineMarketing from "./pages/services/online-marketing";
import OnlineShop from "./pages/services/online-shop";
import Seo from "./pages/services/seo";
import SiteMaintenance from "./pages/services/site-maintenance";
import Blog from "./pages/blog";
import CreateArticle from "./pages/CreateArticle";
import EditArticle from "./pages/EditArticle";
import Article from "./pages/article";
import HostingArticle from "./pages/articles/hosting";
import FrameworksArticle from "./pages/articles/frameworks";

const Main = ({history}) => {
    useEffect(() => history.listen(() => {
        sal({
            threshold: 0.1,
            once: true
        });




    }), [history])

    return (

            <div>
                <ScrollToTop/>
                <Switch> {/* The Switch decides which component to show based on the current URL.*/}
                    <Route exact path='/' component={Home}></Route>
                    <Route exact path='/about-us' component={AboutUs}></Route>
                    <Route exact path='/contact' component={Contact}></Route>
                    <Route exact path='/portofolio' component={Portofolio}></Route>
                    <Route exact path='/web-development' component={WebDevelopment}></Route>
                    <Route exact path='/branding' component={Branding}></Route>
                    <Route exact path='/online-marketing' component={OnlineMarketing}></Route>
                    <Route exact path='/online-shop' component={OnlineShop}></Route>
                    <Route exact path='/seo' component={Seo}></Route>
                    <Route exact path='/site-maintenance' component={SiteMaintenance}></Route>
                    <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/cookie-policy" component={CookiePolicy} />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/create-article" component={CreateArticle} />
                    <Route exact path="/blog/article/edit/:postId" component={EditArticle} />
                    <Route exact path="/blog/article/:slug" component={Article} />
                    <Route exact path="/blog/articles/choosing-domain-and-hosting" component={HostingArticle} />
                    <Route exact path="/blog/articles/javascript-frameworks" component={FrameworksArticle} />
                    <Route component={NotFound} />
                </Switch>
            </div>

    );
}

export default withRouter(Main);