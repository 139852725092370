import { useState, useEffect } from 'react';
import axios from "axios";

const useForm = (callback, validate) => {
    const [values, setValues] = useState({
        name: "",
        company: "",
        email: "",
        message: "",
        privacy: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    useEffect(
        () => {
            if (Object.keys(errors).length === 0 && isSubmitting) {
                //callback();
                var qs = require('qs');
                axios({
                    method: "POST",
                    url:"https://digitald.studio/mail/server.php",
                    data:  qs.stringify(values)
                }).then((response)=>{
                    if (response.data.status === 'success') {
                        document.querySelector('.contact-form-box .title').textContent="Your message has been sent";
                        document.querySelector(".axil-contact-form").remove();

                        // alert("Message Sent.");  //Change Message Here
                    } else if (response.data.status === 'fail') {
                        // alert("Message failed to send.")  //Change Message Here
                    }
                })

            }
        },
        [errors, callback, isSubmitting, values]
    );

    return { handleChange, handleSubmit, values, errors };
};

export default useForm;
