import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
    gtmId: 'GTM-KBNFGPM',
    dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render((

        <BrowserRouter>
            <App /> {/* The various pages will be displayed by the `Main` component. */}
        </BrowserRouter>
    ), document.getElementById('root')
);

