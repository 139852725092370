import Cookies from 'js-cookie/dist/js.cookie';



function Switcher() {


    function changeTheme() {
        if (document.body.classList.contains('active-dark-mode')) {
            Cookies.remove('_darkmode');
            Cookies.set('_lightmode', 'Enabled', { expires: 7 });
            document.body.classList.remove('active-dark-mode');
            document.body.classList.add('active-light-mode');

        } else if (document.body.classList.contains('active-light-mode')) {
            Cookies.remove('_lightmode');
            Cookies.set('_darkmode', 'Enabled', { expires: 7 });
            document.body.classList.remove('active-light-mode');
            document.body.classList.add('active-dark-mode');
            console.log("Setted Cookie light");
        } else  {
            Cookies.set('_darkmode', 'Enabled', { expires: 7 });
            document.body.classList.add('active-dark-mode');
            console.log("Setted Cookie dark for the first time");
        }
    }



    return (
        <div className="my_switcher d-none d-lg-block" onClick={changeTheme}>
            <ul>
                <li title="Light Mode">
                    <button href={'# '}  className="setColor light">
                        <i className="fal fa-lightbulb-on"></i>
                    </button>
                </li>
                <li title="Dark Mode">
                    <button href={'# '}  className="setColor dark">
                        <i className="fas fa-moon"></i>
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default Switcher;