import { CookieBanner } from "@palmabit/react-cookie-law";



function cookieBanner() {


    return (
        <div>
            <CookieBanner
                message="We use cookies to improve user experience. Choose what cookies you allow us to use. You can read more about our Cookie Policy in our Cookie policy page"
                showDeclineButton={true}
                wholeDomain={true}
                declineButtonText={"Decline"}
                managePreferencesButtonText={"Manage my cookies"}
                onAccept={() => {}}
                policyLink={"/cookie-policy"}
                privacyPolicyLinkText={"Cookie Policy"}
                onAcceptPreferences={() => {}}
                onAcceptStatistics={() => {}}
                onAcceptMarketing={() => {}}
                styles={{
                    dialog: { top: "auto", bottom: "0px", position: "fixed", left: "0", right: "0", zIndex: "10000", padding: "10px", backgroundColor: "#fff" },
                }}
            />
        </div>
    );

}

export default cookieBanner;
