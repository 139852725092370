import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link, useHistory} from "react-router-dom";
import EstimateProject from "../sections/estimateProject";
import axios from "axios";
import TagManager from "react-gtm-module";


function Blog() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }
    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/blog",
            page: 'Blog'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    const [postList, setPostList] = useState([]);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let history = useHistory();
    useEffect(() => {
        axios.get('http://localhost:3002/api/post/get').then((data) => {
           setPostList(data.data);
           console.log()
        })
    }, []);
    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'online-shop'}>
                <Helmet>
                    <title>Blog</title>
                    <meta name="description" content="Digital Designs Studio -  Online Shops, E-commerce services" />
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><Link to="/">Home</Link></li>
                                <li className="active">Blog</li>
                            </ul>
                            <h1 className="title h2">Blog</h1>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100"><img src="/media/others/bubble-9.png" alt="Bubble"/></li>
                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200"><img src="/media/others/bubble-10.png" alt="Bubble"/></li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300"><img src="/media/others/line-4.png" alt="Line"/></li>
                    </ul>
                </div>

                <section className="section-padding-equal">
                    <div className="container">
                        <div className="row row-40">
                            <div className="col-lg-8">
                                {postList.map((val, key) => {
                                    return (
                                        <div className="blog-grid">
                                            <h3 className="title" onClick={() => {history.push(`/blog/article/${val.slug}`)}}>{val.title}</h3>
                                            <div className="author">
                                                <div className="author-thumb">
                                                    <img src="/media/blog/author-1.png" alt="Blog Author"/>
                                                </div>
                                                <div className="info">
                                                    <h6 className="author-name">{val.username}</h6>
                                                    <ul className="blog-meta list-unstyled">
                                                        <li> {new Date(val.date_posted).toLocaleDateString('en-EN', options)}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="post-thumbnail">
                                                <a href="single-blog.html"><img src="/media/blog/blog-3.jpg" alt="Blog"/></a>
                                            </div>
                                            <p>{val.post_text}</p>

                                            <a href="single-blog.html" className="axil-btn btn-borderd btn-large">Read More</a>
                                        </div>
                                    )
                                })}
                                <div className="blog-grid">
                                    <h3 className="title"><Link to="/blog/articles/choosing-domain-and-hosting">Choosing the Domain and Hosting for your site</Link></h3>
                                    <div className="author">
                                        <div className="author-thumb">
                                            <img src="/media/blog/author-1.png" alt="Blog Author"/>
                                        </div>
                                        <div className="info">
                                            <h6 className="author-name">Bogdan M.</h6>
                                            <ul className="blog-meta list-unstyled">
                                                <li>Feb 02, 2022</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="post-thumbnail">
                                        <Link to="/blog/articles/choosing-domain-and-hosting"><img src="/media/blog/blog-3.jpg" alt="Blog"/></Link>
                                    </div>
                                    <p>If you are thinking of starting an online business, one of the first things you have to consider is what domain and hosting you are going to use. These questions are not only important for the most technical part, but they have a lot to do with the success of your company: If your website goes unnoticed in ....</p>
                                    <Link to="/blog/articles/choosing-domain-and-hosting" className="axil-btn btn-borderd btn-large">Read More</Link>
                                </div>
                                {/*<div className="blog-grid blog-without-thumb">*/}
                                {/*    <h3 className="title"><a href="single-blog.html">Twice Profit Than Before You Ever*/}
                                {/*        Got</a></h3>*/}
                                {/*    <div className="author">*/}
                                {/*        <div className="info">*/}
                                {/*            <h6 className="author-name">Theresa Underwood</h6>*/}
                                {/*            <ul className="blog-meta list-unstyled">*/}
                                {/*                <li>Sep 9, 2021</li>*/}
                                {/*            </ul>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="blog-grid">
                                    <h3 className="title"> <Link to="/blog/articles/javascript-frameworks">Most Used Javascript Frameworks of 2021</Link></h3>
                                    <div className="author">
                                        <div className="author-thumb">
                                            <img src="/media/blog/author-1.png" alt="Blog Author"/>
                                        </div>
                                        <div className="info">
                                            <h6 className="author-name">Bogdan M.</h6>
                                            <ul className="blog-meta list-unstyled">
                                                <li>Dec 28, 2021</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="post-thumbnail">
                                        <Link to="/blog/articles/javascript-frameworks"><img src="/media/blog/blog-4.jpg"
                                                                                             alt="Blog"/></Link>
                                    </div>
                                    <p>Here you can find the trend from last year when it comes to Javascript Frameworks. Most used framework is ...</p>
                                    <Link to="/blog/articles/javascript-frameworks"  className="axil-btn btn-borderd btn-large">Read More</Link>
                                </div>
                                {/*<div className="blog-grid blog-without-thumb">*/}
                                {/*    <blockquote>*/}
                                {/*        <h5 className="title"><a href="single-blog-quote.html">“ The goal of a designer*/}
                                {/*            is to listen, observe, understand, sympathize, empathize, synthesize, and*/}
                                {/*            glean insights that enable him or her to make the invisible visible ”</a>*/}
                                {/*        </h5>*/}
                                {/*    </blockquote>*/}
                                {/*    <div className="author">*/}
                                {/*        <div className="info">*/}
                                {/*            <h6 className="author-name">Theresa Underwood</h6>*/}
                                {/*            <ul className="blog-meta list-unstyled">*/}
                                {/*                <li>Sep 12, 2021</li>*/}
                                {/*                <li>10 min to read</li>*/}
                                {/*            </ul>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="blog-grid blog-thumb-slide">*/}
                                {/*    <h3 className="title"><a href="single-blog-gallery.html">eCommerce Marketing*/}
                                {/*        Statistics You Should Know in 2021.</a></h3>*/}
                                {/*    <div className="author">*/}
                                {/*        <div className="author-thumb">*/}
                                {/*            <img src="/media/blog/author-2.png" alt="Blog Author"/>*/}
                                {/*        </div>*/}
                                {/*        <div className="info">*/}
                                {/*            <h6 className="author-name">Theresa Underwood</h6>*/}
                                {/*            <ul className="blog-meta list-unstyled">*/}
                                {/*                <li>Sep 15, 2021</li>*/}
                                {/*                <li>8 min to read</li>*/}
                                {/*            </ul>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*    <p>Above all, think of life as a prototype. We can conduct experiments, make*/}
                                {/*        discoveries, and change our perspectives. We can look for opportunities to turn*/}
                                {/*        processes into projects that have tangible outcomes. We can learn how to take*/}
                                {/*        joy in the things we create whether they take the form of a fleeting experience*/}
                                {/*        or an heirloom that will last for generations.</p>*/}
                                {/*    <a href="single-blog-gallery.html" className="axil-btn btn-borderd btn-large">Read*/}
                                {/*        More</a>*/}
                                {/*</div>*/}
                                {/*<div className="blog-grid mb-0">*/}
                                {/*    <h3 className="title"><a href="single-blog-video.html">Follow your own design*/}
                                {/*        process, whatever gets you to the outcome.</a></h3>*/}
                                {/*    <div className="author">*/}
                                {/*        <div className="author-thumb">*/}
                                {/*            <img src="/media/blog/author-2.png" alt="Blog Author"/>*/}
                                {/*        </div>*/}
                                {/*        <div className="info">*/}
                                {/*            <h6 className="author-name">Theresa Underwood</h6>*/}
                                {/*            <ul className="blog-meta list-unstyled">*/}
                                {/*                <li>Sep 10, 2021</li>*/}
                                {/*                <li>9 min to read</li>*/}
                                {/*            </ul>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="post-thumbnail">*/}
                                {/*        <a href="single-blog-video.html"><img src="/media/blog/blog-4.png"*/}
                                {/*                                              alt="Blog"/></a>*/}
                                {/*        <div className="popup-video">*/}
                                {/*            <a href="https://www.youtube.com/watch?v=1iIZeIy7TqM"*/}
                                {/*               className="play-btn popup-youtube"><i className="fas fa-play"></i></a>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <p>Above all, think of life as a prototype. We can conduct experiments, make*/}
                                {/*        discoveries, and change our perspectives. We can look for opportunities to turn*/}
                                {/*        processes into projects that have tangible outcomes. We can learn how to take*/}
                                {/*        joy in the things we create whether they take the form of a fleeting experience*/}
                                {/*        or an heirloom that will last for generations.</p>*/}
                                {/*    <a href="single-blog-video.html" className="axil-btn btn-borderd btn-large">Read*/}
                                {/*        More</a>*/}
                                {/*</div>*/}
                                {/*<div className="pagination">*/}
                                {/*    <ul>*/}
                                {/*        <li><a className="prev page-numbers" href="#"><i*/}
                                {/*            className="fal fa-arrow-left"></i></a></li>*/}
                                {/*        <li><a href="#" className="page-numbers current">1</a></li>*/}
                                {/*        <li><a className="page-numbers" href="#">2</a></li>*/}
                                {/*        <li><a className="page-numbers" href="#">3</a></li>*/}
                                {/*        <li><a className="page-numbers" href="#">4</a></li>*/}
                                {/*        <li><a className="page-numbers" href="#">5</a></li>*/}
                                {/*        <li><a className="next page-numbers" href="#"><i*/}
                                {/*            className="fal fa-arrow-right"></i></a></li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>
                            <div className="col-lg-4">
                                <div className="axil-sidebar">


                                    <div className="widget widge-social-share">
                                        <div className="blog-share">
                                            <h5 className="title">Follow:</h5>
                                            <ul className="social-list list-unstyled">
                                                <li><a href="https://www.facebook.com/digitald.studio" target='_blank'><i
                                                    className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="https://twitter.com/digitald_studio" target='_blank'><i
                                                    className="fab fa-twitter"></i></a></li>
                                                <li><a href="https://www.instagram.com/digitaldesign_studio/" target='_blank'><i
                                                    className="fab fa-instagram"></i></a></li>

                                            </ul>
                                        </div>
                                    </div>
                                    {/*<div className="widget widget-recent-post">*/}
                                    {/*    <h4 className="widget-title">Recent post</h4>*/}
                                    {/*    <div className="post-list-wrap">*/}
                                    {/*        <div className="single-post">*/}
                                    {/*            <div className="post-thumbnail">*/}
                                    {/*                <a href="single-blog.html"><img src="/media/blog/blog-5.png"*/}
                                    {/*                                                alt="Blog"/></a>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="post-content">*/}
                                    {/*                <h6 className="title"><a href="single-blog.html">Take ownership and*/}
                                    {/*                    question the status quo in.</a></h6>*/}
                                    {/*                <ul className="blog-meta list-unstyled">*/}
                                    {/*                    <li>Sep 10, 2021</li>*/}
                                    {/*                    <li>9 min to read</li>*/}
                                    {/*                </ul>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="single-post">*/}
                                    {/*            <div className="post-thumbnail">*/}
                                    {/*                <a href="single-blog.html"><img src="/media/blog/blog-6.png"*/}
                                    {/*                                                alt="Blog"/></a>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="post-content">*/}
                                    {/*                <h6 className="title"><a href="single-blog.html">Take ownership and*/}
                                    {/*                    question the status quo in.</a></h6>*/}
                                    {/*                <ul className="blog-meta list-unstyled">*/}
                                    {/*                    <li>Sep 15, 2021</li>*/}
                                    {/*                    <li>15 min to read</li>*/}
                                    {/*                </ul>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="single-post">*/}
                                    {/*            <div className="post-thumbnail">*/}
                                    {/*                <a href="single-blog.html"><img src="/media/blog/blog-7.png"*/}
                                    {/*                                                alt="Blog"/></a>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="post-content">*/}
                                    {/*                <h6 className="title"><a href="single-blog.html">Take ownership and*/}
                                    {/*                    question the status quo in.</a></h6>*/}
                                    {/*                <ul className="blog-meta list-unstyled">*/}
                                    {/*                    <li>Sep 5, 2021</li>*/}
                                    {/*                    <li>8 min to read</li>*/}
                                    {/*                </ul>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="widget widget-banner-ad">
                                        {/*<a href="#">*/}
                                        {/*    <img src="/media/banner/widget-banner.png" alt="banner"/>*/}
                                        {/*</a>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <EstimateProject/>
            </main>
        </HelmetProvider>
    );
}

export default Blog;
