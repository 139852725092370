import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link} from "react-router-dom";
import TagManager from "react-gtm-module";


function SiteMaintenance() {

    function doNothing(e) {
        e.preventDefault();
        console.log('onclick..')
    }

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/site-maintenance",
            page: 'Site Maintenance'
        },
        dataLayerName: 'PageDataLayer'
    }


    useEffect(() => {
        TagManager.dataLayer(tagManagerArgs);
        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'online-shop'}>
                <Helmet>
                    <title>Website Maintenance, Optimization and backups</title>
                    <meta name="description" content="Digital Designs Studio -  Online Shops, E-commerce services" />
                </Helmet>
                <div className="breadcrum-area breadcrumb-banner">

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content" data-sal="slide-up" data-sal-duration="800" data-sal-delay="400">
                                    <h2 className="title">Site Maintenance</h2>
                                    <p>Monitoring your website's overall health and performance at affordable prices. </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="thumbnail" data-sal="zoom-in" data-sal-duration="800"
                                     data-sal-delay="400"><img src="/media/others/site-maintenance.png" alt="site maintenance"/></div>
                            </div>
                        </div>
                    </div>

                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1 sal-animate" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2 sal-animate" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200">
                            <img src="/media/others/bubble-21.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3 sal-animate" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>

                <section className="section section-padding">
                    <div className="container">
                        <div className="section-heading mb-0">
                            {/*<span className="subtitle">Branding Services*</span>*/}
                            {/*<h2 className="title">Find the Right Package.</h2>*/}
                            {/*<p>These packages are best suited for <br/> personal, blogs, corporate sites.</p>*/}
                        </div>

                        <div className="row">

                            <div className="col-12 col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="200">
                                <div className="pricing-table pricing-borderd">

                                    <div className="pricing-header">
                                        <h3 className="title">Basic Package</h3>
                                        <span className="subtitle">Minimum offer covering 1 hour/month</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">monthly*</span>
                                                <span className="amount">€24</span>

                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <Link to="/contact" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</Link>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">Updates for CMS and Plugins</li>
                                            <li className="yes">Backups</li>
                                            <li className="yes">Support via e-mail</li>
                                            <li>Support via phone</li>
                                            <li>Support via Whatsapp</li>
                                            <li className="yes">Development 1 hour/month</li>
                                            <li className="yes">10% discount for extra hours</li>

                                        </ul>
                                    </div>

                                </div>

                            </div>


                            <div className="col-12 col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="200">
                                <div className="pricing-table pricing-borderd">

                                    <div className="pricing-header">
                                        <h3 className="title">Standard Package</h3>
                                        <span className="subtitle">Keep your site updated and bug free</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">monthly*</span>
                                                <span className="amount">€49</span>

                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <Link to="/contact" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</Link>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">Updates for CMS and Plugins</li>
                                            <li className="yes">Backups</li>
                                            <li className="yes">Support via e-mail</li>
                                            <li className="yes">Support via phone</li>
                                            <li>Support via Whatsapp</li>
                                            <li className="yes">Development 2 hour/month</li>
                                            <li className="yes">15% discount for extra hours</li>

                                        </ul>
                                    </div>

                                </div>

                            </div>



                            <div className="col-12 col-lg-4" data-sal="slide-up" data-sal-duration="800"
                                 data-sal-delay="200">
                                <div className="pricing-table pricing-borderd">

                                    <div className="pricing-header">
                                        <h3 className="title">Premium Package</h3>
                                        <span className="subtitle">Keep your site updated and bug free</span>
                                        <div className="price-wrap">
                                            <div className="yearly-pricing">
                                                <span className="duration">monthly*</span>
                                                <span className="amount">€89</span>

                                            </div>

                                        </div>
                                        <div className="pricing-btn">
                                            <Link to="/contact" className="axil-btn btn-large-fluid btn-borderd">Get Started
                                                Today</Link>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-unstyled">
                                            <li className="yes">Updates for CMS and Plugins</li>
                                            <li className="yes">Backups</li>
                                            <li className="yes">Support via e-mail</li>
                                            <li className="yes">Support via phone</li>
                                            <li className="yes">Support via Whatsapp</li>
                                            <li className="yes">Development 3 hour/month</li>
                                            <li className="yes">20% discount for extra hours</li>

                                        </ul>
                                    </div>

                                </div>

                            </div>



                        </div>

                    </div>
                    <ul className="shape-group-3 list-unstyled">
                        <li className="shape shape-2"><img src="/media/others/bubble-4.png" alt="shape"/></li>
                    </ul>
                </section>



                {/*<section className="section section-padding bg-color-light pb--70">*/}
                {/*    <div className="container">*/}
                {/*        <div className="section-heading mb--90">*/}
                {/*            <span className="subtitle">Brand Awareness</span>*/}
                {/*            <h2 className="title">How to creat a powerfull brand strategy</h2>*/}

                {/*        </div>*/}
                {/*        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"*/}
                {/*             data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-1.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step One</span>*/}
                {/*                <h3 className="title">What makes you different?</h3>*/}
                {/*                <p>You have to identify a trait that sets you apart from your competition.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="process-work content-reverse" data-sal="slide-left"*/}
                {/*             data-sal-duration="1000" data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-2.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step Two</span>*/}
                {/*                <h3 className="title">Be Consistent</h3>*/}
                {/*                <p>Try to be consistent on all the channels that your brand is using so you don't confuse your customers.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"*/}
                {/*             data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-3.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step Three</span>*/}
                {/*                <h3 className="title">Greet your Clients with Positivity</h3>*/}
                {/*                <p>All brand strategies have to be Full of Positivity. Your clients want to feel good.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="process-work content-reverse" data-sal="slide-left"*/}
                {/*             data-sal-duration="1000" data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-4.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step Four</span>*/}
                {/*                <h3 className="title">About Us Page</h3>*/}
                {/*                <p>This applyes to your website but the same is with the Bio section from instagram or Facebook. Let customers know why you are different.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="process-work" data-sal="slide-right" data-sal-duration="1000"*/}
                {/*             data-sal-delay="100">*/}
                {/*            <div className="thumbnail paralax-image">*/}
                {/*                <img src="/media/others/branding/process-5.png" alt="Thumbnail"/>*/}
                {/*            </div>*/}
                {/*            <div className="content">*/}
                {/*                <span className="subtitle">Step Five</span>*/}
                {/*                <h3 className="title">Strengthen everything with your logo, name and url</h3>*/}
                {/*                <p>Make every small detail count and use the opportunities that come with your logo and business name to make it easy to remember.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <ul className="shape-group-17 list-unstyled">*/}
                {/*        <li className="shape shape-1"><img src="/media/others/bubble-24.png" alt="Bubble"/></li>*/}
                {/*        <li className="shape shape-2"><img src="/media/others/bubble-23.png" alt="Bubble"/></li>*/}
                {/*        <li className="shape shape-3"><img src="/media/others/line-4.png" alt="Line"/></li>*/}
                {/*        <li className="shape shape-4"><img src="/media/others/line-5.png" alt="Line"/></li>*/}
                {/*        <li className="shape shape-5"><img src="/media/others/line-4.png" alt="Line"/></li>*/}
                {/*        <li className="shape shape-6"><img src="/media/others/line-5.png" alt="Line"/></li>*/}
                {/*    </ul>*/}
                {/*</section>*/}



            </main>
        </HelmetProvider>
    );
}

export default SiteMaintenance;
