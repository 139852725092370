import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import EstimateProject from "../sections/estimateProject";
import PortofolioList from "../sections/portofolioList";
import TagManager from "react-gtm-module";

function Portofolio() {

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/portofoliog",
            page: 'Portofolio'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)


    useEffect(() => {

        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'portofolio'}>
                <Helmet>
                    <title>Portofolio - Digital Designs Studio</title>
                </Helmet>

                <div className="breadcrum-area breadcrumb-banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-heading heading-left" data-sal="slide-right"
                                     data-sal-duration="1000" data-sal-delay="300">
                                    <h1 className="title h2">Our portofolio</h1>
                                    <p>A quick view of industry specific problems resolved with clear solutions by our team.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumbnail" data-sal="slide-up" data-sal-duration="1000"
                                     data-sal-delay="400">
                                    <img className="paralax-image" src="/media/banner/banner-thumb-1.png"
                                         alt="Illustration"/>
                                </div>
                            </div>
                        </div>


                    </div>
                    <ul class="shape-group-8 list-unstyled">
                        <li class="shape shape-1" data-sal="slide-right" data-sal-duration="500" data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li class="shape shape-2" data-sal="slide-left" data-sal-duration="500" data-sal-delay="200">
                            <img src="/media/others/bubble-20.png" alt="Bubble"/>
                        </li>
                        <li class="shape shape-3" data-sal="slide-up" data-sal-duration="500" data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>


                <PortofolioList/>


                <EstimateProject/>
            </main>
        </HelmetProvider>
    );
}

export default Portofolio;
