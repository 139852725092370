import React from 'react';
import {Link} from "react-router-dom";


const FormCheckbox = ({ handleChange, error, ...otherProps }) => (
    <div className="form-group mb--40">
        <div className="privacyContainer d-flex">
            <input type="checkbox" required className="form-check-input" name="privacy" value="1" onChange={handleChange} {...otherProps}/>
            <span>I have read and agree to the <Link to="/terms-and-conditions" target="_blank">Terms of Use </Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.</span>

        </div>
        <span className="text-danger">{error}</span>
    </div>

);

export default FormCheckbox;