import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Link, useParams} from "react-router-dom";
import EstimateProject from "../../sections/estimateProject";
import axios from "axios";
import TagManager from "react-gtm-module";


function HostingArticle() {

    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/blog/articles/choosing-domain-and-hosting",
            page: 'Hosting Article'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    let {postId} = useParams();
    let {slug} = useParams();
    const [post, setPost] = useState({});
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    useEffect(() => {

        axios.get(`http://localhost:3002/api/post/getFromId/${slug}`).then((data) => {
            setPost({ title: data.data[0].title, slug: data.data[0].slug, username: data.data[0].username, date: data.data[0].date_posted})
            console.log(data);
        })
    }, []);

    useEffect(() => {
        console.log(postId);
        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'online-shop'}>
                <Helmet>
                    <title>Choosing the Domain and Hosting for your site</title>
                    <meta name="description" content="Digital Designs Studio -  Online Shops, E-commerce services" />
                </Helmet>
                <div className="breadcrum-area">
                    <div className="container">
                        <div className="breadcrumb">
                            <ul className="list-unstyled">
                                <li><Link to="/">Home</Link></li>
                                <li className="active">Blog</li>
                            </ul>
                            <h1 className="title h2">Choosing the Domain and Hosting for your site</h1>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100"><img src="/media/others/bubble-9.png" alt="Bubble"/></li>
                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200"><img src="/media/others/bubble-10.png" alt="Bubble"/></li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300"><img src="/media/others/line-4.png" alt="Line"/></li>
                    </ul>
                </div>

                <section className="section-padding-equal">
                    <div className="container">
                        <div className="row row-40">
                            <div className="col-lg-8">
                                <div className="single-blog">
                                    <div className="single-blog-content blog-grid">
                                        <div className="post-thumbnail">
                                            <img src="/media/blog/blog-3.jpg" alt="Blog"/>
                                        </div>
                                        <div className="author">
                                            <div className="author-thumb">
                                                <img src="/media/blog/author-1.png" alt="Blog Author"/>
                                            </div>
                                            <div className="info">
                                                <h6 className="author-name">Bogdan M.</h6>
                                                <ul className="blog-meta list-unstyled">
                                                    <li>Feb 02, 2022</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p>If you are thinking of starting an online business, one of the first things you have to consider is what domain and hosting you are going to use. These questions are not only important for the most technical part, but they have a lot to do with the success of your company: If your website goes unnoticed in search engines, it will cost you much more to get afloat.</p>

                                        <h3 className="title mb--30">The Domain: your image on the Internet</h3>
                                        <p>Choosing a good name for your brand is just as important as choosing the domain it will have on the Internet. Here are some tips for your domain to effectively represent your company and at the same time be beneficial for SEO:</p>

                                           <p> 1. It is important that it is short and easy to write. It is best that the words you use are neither difficult nor excessively long. Also, you should avoid numbers and hyphens because they lead to confusion.</p>
                                        <p>2. Decide if you are going to use only your brand name or also keywords. Although the brand represents the identity of a product, many e-commerce use keywords to describe their company and the services it offers. If you decide to put keywords, first do an analysis to find out what words users search for when they need the services that your company offers. In this way, you will improve your SEO positioning and web traffic.</p>

                                           <p> 3. Make them remember you. There are millions of registered domain names, so having an eye-catching domain that matches your business is essential. Once you have thought about it, you can ask your friends to give you their opinion to make sure it sounds good (remember that the ones who have to find you are the users, so don't just think of something that you like).</p>
                                        <p>4. Check that the domain you have chosen is available. Make sure that the name you have chosen does not exist, is not copyrighted, and is not being used by another company.</p>
                                        <p>One of the most important aspects when choosing a domain, and therefore deserves special attention, is to think about the extension. Not all domain extensions are the same or get the same SEO results:</p>
                                        <p>The ideal would be to always use generic domains such as .com or .net, since they do not have any type of territorial or activity restriction.</p>

                                        <h3 className="title mb--30">Hosting: insurance for your website</h3>
                                        <p className="mb--40">To choose the Hosting that works best with your website and the business idea you have in mind, there are a series of questions that you must take into account:</p>
                                        <p><strong>Objectives</strong>. You must know what the orientation of your website will be (personal or professional) and what type it should be (static or dynamic). A professional website will always attract much more traffic and will need plugins and other tools, while a personal one may not need too complex planning. Regarding the type, you must bear in mind that a static web is made up of a collection of very simple HTML pages with a fixed content, while in a dynamic web the content changes continuously (such as discussion forums, wikis, etc.). blogs like WordPress and online stores with Prestashop or Magento).</p>
                                        <p><strong>The traffic you anticipate for your website</strong>. If from the outset you see that your website will have enormous traffic, you should choose a type of Hosting that can support it without the server going down.</p>
                                        <p><strong>Server disk space and monthly transfer</strong>. Today, most Hostings offer "unlimited" space, implying that you will never consume the bandwidth they offer you.</p>
                                        <p><strong></strong>Availability. The standard figure is 99% to make sure the server doesn't go down.</p>
                                        <p><strong>Web Loading Speed</strong>. Surely one of the most important issues, because if your website takes too long to load, Google will penalize you in the search results and you will also lose traffic. Think that users are looking for usable websites where they can find what they are looking for in the shortest possible time.</p>
                                        <p>Before we decided on the hosting of this website we made a lot of research. And a great balance of speed, uptime and support is A2Hosting. You can check the plans <a href="http://www.a2hosting.com/refer/192865">here</a> (link has referal code).</p>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="featured-img">
                                                    <img src="/media/blog/blog-8.jpg" alt="Blog"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="featured-img">
                                                    <img src="/media/blog/blog-9.jpg" alt="Blog"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-author">
                                        <div className="author">
                                            <div className="author-thumb">
                                                <img src="/media/blog/author-1.png" alt="Blog Author"/>
                                            </div>
                                            <div className="info">
                                                <h5 className="title"><a href="#">Bogdan M.</a></h5>
                                                <p>The brain behind all this business</p>
                                                <ul className="social-share list-unstyled">
                                                    <li><a href="https://www.facebook.com/Bogdan.Duracell"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="https://twitter.com/nubyan_official"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="https://www.linkedin.com/in/munteanu-bogdan-37152a155/"><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="https://www.instagram.com/nubyan_motovlog/"><i className="fab fa-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="axil-sidebar">

                                    {/*<div className="widget widget-categories">*/}
                                    {/*    <h4 className="widget-title">Categories</h4>*/}
                                    {/*    <ul className="category-list list-unstyled">*/}
                                    {/*        <li><a href="blog-category.html">Agency news</a></li>*/}
                                    {/*        <li><a href="blog-category.html">Blog</a></li>*/}
                                    {/*        <li><a href="blog-category.html">Information technology</a></li>*/}
                                    {/*        <li><a href="blog-category.html">New ideas</a></li>*/}
                                    {/*        <li><a href="blog-category.html">Uncategorized</a></li>*/}
                                    {/*        <li><a href="blog-category.html">Marketing &amp; branding</a></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                    <div className="widget widge-social-share">
                                        <div className="blog-share">
                                            <h5 className="title">Follow:</h5>
                                            <ul className="social-list list-unstyled">
                                                <li><a href="https://www.facebook.com/digitald.studio" target='_blank'><i
                                                    className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="https://twitter.com/digitald_studio" target='_blank'><i
                                                    className="fab fa-twitter"></i></a></li>
                                                <li><a href="https://www.instagram.com/digitaldesign_studio/" target='_blank'><i
                                                    className="fab fa-instagram"></i></a></li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="widget widget-banner-ad">
                                        {/*<a href="#">*/}
                                        {/*    <img src="/media/banner/widget-banner.png" alt="banner"/>*/}
                                        {/*</a>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <EstimateProject/>
            </main>
        </HelmetProvider>
    );
}

export default HostingArticle;
