import React, {useEffect, useState} from "react";
import sal from "sal.js";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import EstimateProject from "../sections/estimateProject";
import TagManager from "react-gtm-module";


function AboutUs() {
    const [doc, setDocData] = useState(null);


    const tagManagerArgs = {
        dataLayer: {
            event: 'page-view',
            url: "/about-us",
            page: 'About us - Digital Designs Studio'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)



    useEffect(() => {


        sal({
            threshold: 0.1,
            once: true
        });
    }, []);

    return (
        <HelmetProvider>
            <main className={'about-us'}>
                <Helmet>
                    <title>About us - Digital Designs Studio</title>
                </Helmet>
                <div className="breadcrum-area breadcrumb-banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-heading heading-left" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="300">
                                    <h1 className="title h2">About us</h1>
                                    <p className="col-12 col-md-4 ">Having an experience of over 9 years in the world of webdesign and development, we decided to create our company in 2021 as a perfect bridge between you and your clients/target.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumbnail thumbnail-4 " data-sal="slide-up" data-sal-duration="1000" data-sal-delay="400">
                                    <img src="/media/banner/banner-thumb-3.png" alt="Illustration"/>
                                </div>
                            </div>
                        </div>


                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1" data-sal="slide-right" data-sal-duration="500"
                            data-sal-delay="100">
                            <img src="/media/others/bubble-9.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-2" data-sal="slide-left" data-sal-duration="500"
                            data-sal-delay="200">
                            <img src="/media/others/bubble-22.png" alt="Bubble"/>
                        </li>
                        <li className="shape shape-3" data-sal="slide-up" data-sal-duration="500"
                            data-sal-delay="300">
                            <img src="/media/others/line-4.png" alt="Line"/>
                        </li>
                    </ul>
                </div>
                <section className="section section-padding case-study-featured-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-6">
                                <div className="case-study-featured-thumb text-start">
                                    <img src="/media/others/case-study-4.png" alt="travel"/>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="case-study-featured">
                                    <div className="section-heading heading-left">
                                        {/*<span className="subtitle">Who we are</span>*/}
                                        <h2 className="title">Bringing you closer to your target audience</h2>
                                        <p>We are a small web design and development agency based in Bucharest. If you have an idea for a website, but don't know where to start we can help. We work with all kinds of organisations including local authorities, charities, startups and SMEs.
                                            </p>
                                        <p>Whether it's your first time designing a website or your business has been around for years we can help build the perfect site for you.</p>
                                        {/*<a href="#" className="axil-btn btn-fill-primary btn-large">Read More</a>*/}
                                    </div>
                                    {/*<div className="case-study-counterup">*/}
                                    {/*    <div className="single-counterup">*/}
                                    {/*        <h2 className="count-number">*/}
                                    {/*            <span className="number count">0</span>*/}
                                    {/*            <span className="symbol">+</span>*/}
                                    {/*        </h2>*/}
                                    {/*        <span className="counter-title">Years on the market</span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="single-counterup">*/}
                                    {/*        <h2 className="count-number">*/}
                                    {/*            <span className="number count">13</span>*/}
                                    {/*            <span className="symbol">+</span>*/}
                                    {/*        </h2>*/}
                                    {/*        <span className="counter-title">Projects delivered so far</span>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <EstimateProject/>
            </main>
        </HelmetProvider>
    );
}

export default AboutUs;
